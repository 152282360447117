import React, { FC } from "react";
import { CustomerDetail, CustomerReviewWrapper, ReviewCardContainer, SectionHeader, SliderAction, SliderContainer, SliderNextButton, SliderPrevButton } from "./style";
import { Head2, Paragraph, Wrapper } from "../../../../common/constants/core.style";
import { IconSvg } from "../../../../common/constants/images";

interface CustomerReviewSectionProps {

}

interface CustomerReviewCardProps {
    comment: string;
    customerName: string;
    designation: string;
}

const CustomerReviewCard: FC<CustomerReviewCardProps> = (props) => {
    const {
        comment,
        customerName,
        designation,
    } = props

    return (
        <ReviewCardContainer>
            <IconSvg.QuoteCircle/>
            <Paragraph className="text-lg">{comment}</Paragraph>
            <CustomerDetail>
                <Paragraph>{customerName}, {designation}</Paragraph>
            </CustomerDetail>
        </ReviewCardContainer>
    )
}

type Review = {
    customerName: string;
    designation: string;
    comment: string;
};

const ReviewData: Review[] = [
    {
        customerName: "Sarah",
        designation: "Home Service Customer",
        comment: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look",
    },
    {
        customerName: "Michael",
        designation: "Home Service Customer",
        comment: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look",
    }
];


const  CustomerReviewSection: FC<CustomerReviewSectionProps> = () => {
    return (
        <CustomerReviewWrapper>
            <Wrapper>
                <SectionHeader>
                    <Head2>What our customers are saying about our services?</Head2>
                    <SliderAction>
                        <SliderNextButton>
                            <IconSvg.SliderLeftArrow/>
                        </SliderNextButton>
                        <SliderPrevButton>
                            <IconSvg.SliderRightArrow/>
                        </SliderPrevButton>
                    </SliderAction>
                </SectionHeader>
                <SliderContainer>
                {ReviewData.map((item, index) => {
                        return (
                            <CustomerReviewCard
                                customerName={item.customerName}
                                designation={item.designation}
                                comment={item.comment}
                                key={`${item.customerName}-${index}`}
                            />
                        );
                    })}
                </SliderContainer>
            </Wrapper>
        </CustomerReviewWrapper>
    )
}

export default CustomerReviewSection;