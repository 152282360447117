import styled from "styled-components";
import { Wrapper } from "../../../../common/constants/core.style";
import { colors } from "../../../../common/constants/color";




export const TextContent = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: calc(95px + 5%);
    position: relative;
    padding-bottom: 2%;
    @media only screen and (max-width: 1200px) {
        width: 70%;
    }
    @media only screen and (max-width: 991px) {
        width: 100%;
    }
`;
