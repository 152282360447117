import styled from "styled-components";

export const TextContainer = styled.div`
    padding: 30px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    > {
        p {
            margin: 40px 0px;
        }
    }
`

export const ImageContainer = styled.div`
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        border-radius: 16px;
        overflow: hidden;
        width: 100%;
    }

    svg {
        width: 14%;
        height: auto;
        position: absolute;
    }
`

export const TextAndMediaContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 60px 0px;

    &.wc-ltr {
        ${ImageContainer} {
            padding-right: 4%;

            svg {
                top: -6%;
                right: 1%;
            }
        }

        ${TextContainer} {
            padding-right: 12%;
        }
    }

    &.wc-rtl {
        flex-direction: row-reverse;

        ${ImageContainer} {
            padding-left: 4%;

            svg {
                left: -1%;
                bottom: -6%;
            }
        }

        ${TextContainer} {
            padding-right: 6%;
        }
    }
    
    @media only screen and ( max-width: 1024px ) {
        &.wc-rtl {
            ${TextContainer} {
                padding-left: 4%;
                padding-right: 0px;
            }
        }

        &.wc-ltr {
            ${TextContainer} {
                padding-left: 0px;
                padding-right: 4%;
            }
        }

        ${TextContainer} {
            > {
                p {
                    margin: 15px 0px;
                }
            }
        }
    }

    @media only screen and ( max-width: 768px ) {
        flex-direction: column;
        gap: 20px;

        &.wc-rtl {
            flex-direction: column-reverse;
        }

        ${ImageContainer} {
            width: 100%;
        }

        ${TextContainer} {
            width: 100%;
            padding: 0px;
        }
    }
`

export const TextAndMediaContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    z-index: 0;

    &.wc-ltr {
        > {
            svg {
                position: absolute;
                right: -13%;
                width: 26%;
                top: 50%;
                transform: translateY(-50%);
                z-index: -1;
            }
        }
    }

    &.wc-rtl {
        > {
            svg {
                position: absolute;
                left: -13%;
                width: 20%;
                top: 50%;
                transform: translateY(-50%);
                z-index: -1;
            }
        }
    }

    
`