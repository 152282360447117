import axios from 'axios';

// const baseURL = process.env.API_KEY;
const baseURL = "https://api.welcare.net.nz/api/website/";

const apiService = {
  apiToken: '',
  
  
  
  getApi: () => {
    const defaultHeaders = {
      "Content-Type": 'application/json',
      "Accept": 'application/json',
      "Authorization": "Bearer " + apiService.apiToken
    };
    
    let baseUrl: any = baseURL;
    
    const api = axios.create({
      baseURL: baseUrl,
      headers: defaultHeaders,
    });

    return api;
  },

  get: async (url: string, ) => {
    try {
      const response = await apiService.getApi().get(url);
      return response.data;
    } catch (error) {
      apiService.manageError(error);
      throw error;
    }
  },

  post: async (url: string, data: any, ) => {
    try {
      const response = await apiService.getApi().post(url, data);
      return response.data;
    } catch (error) {
      apiService.manageError(error);
      throw error;
    }
  },

  put: async (url: string, data: any, ) => {
    try {
      const response = await apiService.getApi().put(url, data);
      return response.data;
    } catch (error) {
      apiService.manageError(error);
      throw error;
    }
  },

  del: async (url: string, ) => {
    try {
      const response = await apiService.getApi().delete(url);
      return response.data;
    } catch (error) {
      apiService.manageError(error);
      throw error;
    }
  },

  setAccessToken: async (token: string) => {
    await localStorage.setItem('api_token', token);
    apiService.apiToken = token;
    apiService.getApi().defaults.headers.Authorization = `Bearer ${token}`;
    return true;
  },

  getAccessToken: async () => {
    const token = await localStorage.getItem('api_token');
    apiService.apiToken = token? token: '';
    return token;
  },

  manageError: (error: any) => {
    if (error.response) {
      // Handle specific error status codes
      if (error.response.status === 500) {
        // console.log(error.response);
        
        // Internal Server Error (status code: 500)
        // Redirect the user to a "Something went wrong" page
        // Implement your own navigation logic here, for example, using React Navigation.
        // You might have a component named "SomethingWentWrongPage" for this purpose.
        // Replace 'SomethingWentWrongPage' with your actual component name.
        // navigation.navigate('SomethingWentWrongPage');
      } else if (error.response.status === 404) {
        // Not Found (status code: 404)
        // Redirect the user to a "No internet connection" page
        // Implement your own navigation logic here, for example, using React Navigation.
        // You might have a component named "NoInternetConnectionPage" for this purpose.
        // Replace 'NoInternetConnectionPage' with your actual component name.
        // navigation.navigate('NoInternetConnectionPage');
      } else if (error.response.status === 401) {
        // AsyncStorage.clear();
      } else {
        // Handle other error status codes as needed
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log('No response received:', error.request.status);
    } else {
      // Something happened in setting up the request that triggered an error
      console.log('Request setup error:', error.message);
    }

    // Handle the error and provide appropriate feedback to the user
    // ...

    throw error; // Re-throw the error if needed
  },
};

export default apiService;
