import React, { FC } from "react";
import { Head1, Paragraph, Section, Wrapper } from "../../../../common/constants/core.style";
import { ActionContainer, CoustomerCount, CoustomerCountItem, LeftContent, MainSection, MainSectionContent, RightContent } from "./style";
import { IconSvg, Images } from "../../../../common/constants/images";
import { Button } from "../../../../common/components";

interface HeroSectionProps {

}

const HeroSection: FC<HeroSectionProps> = () => {

    return (
        <MainSection>
            <Wrapper>
                <MainSectionContent>
                    <LeftContent>
                        <IconSvg.HomeBannerLeftBg/>
                        <Head1>Providing you with the best health and <span><IconSvg.WordRoundedShape/>wellbeing</span> support services, Welcare- Kaitiakitanga</Head1>
                        <Paragraph className='text-md'>Welcare is a New Zealand-based company that offers comprehensive elderly-care services, mental health,disability and wellbeing support, personalized at-home services, nurturing companionship, and many other services that assist you in getting a helping hand when in need.</Paragraph>
                        <ActionContainer>
                            <Button
                                label="Learn More"
                                url="/about-us"
                            />
                            <div>
                                <IconSvg.HomeButtonShape/>
                            </div>
                        </ActionContainer>
                        <CoustomerCount>
                            <CoustomerCountItem>
                                <span>130k+</span>
                                <span>Customer Happy</span>
                            </CoustomerCountItem>
                            <CoustomerCountItem>
                                <span>50k+</span>
                                <span>Customer Happy</span>
                            </CoustomerCountItem>
                            <CoustomerCountItem>
                                <span>100k+</span>
                                <span>Customer Happy</span>
                            </CoustomerCountItem>
                        </CoustomerCount>
                    </LeftContent>
                    <RightContent>
                        <img src={Images.HomeBannerRightBg} alt="" />
                    </RightContent>
                    <IconSvg.HomeBannerGradient />
                </MainSectionContent>
            </Wrapper>
        </MainSection>
    )
}

export default HeroSection;