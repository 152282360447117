import React, { FC } from "react";
import { Button } from "../../../../common/components";
import { Paragraph } from "../../../../common/constants/core.style";
import {
    SignCardContainer,
} from "./style";
import { Link as RouterLink } from "react-router-dom";

interface SigncardProps {}

const Signcard: FC<SigncardProps> = () => {
  return (
    <SignCardContainer>
        <Button  variant="outline" url={""}>Sign In</Button>
        <Paragraph className="text">or  <RouterLink to={""} className="link-with-underline">Register</RouterLink> to manage your welcare Profile, see your recommended jobs, and keep track of your saved searches.</Paragraph>
    </SignCardContainer>
  );
}; 

export default Signcard;
