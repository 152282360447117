import React, { FC } from "react";
import { ImageContainer, TextAndMediaContainer, TextAndMediaContent, TextContainer } from "./style";
import { Head2, Paragraph, Wrapper } from "../../constants/core.style";
import Button from "../button";
import { IconSvg } from "../../constants/images";

interface TextAndMediaProps {
    image?: string;
    heading: string;
    description?: string;
    buttonText: string;
    buttonUrl: string;
    direction: 'ltr' | 'rtl';
    children?: React.ReactNode;
}

const TextAndMedia: FC<TextAndMediaProps> = ({ image, heading, description, buttonText, buttonUrl, direction = "ltr", children }) => {
    const isLtr = direction === 'ltr';
    const CircleBgShape = isLtr ? IconSvg.CircleBgShape : IconSvg.CircleBgShapeSmall;
    const HighlightShape = isLtr ? IconSvg.HighlightShape : IconSvg.HighlightShapeInverse;

    return (
        <TextAndMediaContainer className={`wc-${direction}`}>
            <CircleBgShape />
            <Wrapper>
                <TextAndMediaContent className={`wc-${direction}`}>
                    <ImageContainer>
                        <img src={image} alt="" />
                        <HighlightShape />
                    </ImageContainer>
                    <TextContainer>
                        <Head2>{heading}</Head2>
                        {children ? children : <Paragraph className="text-md">{description}</Paragraph>}
                        <Button label={buttonText} url={buttonUrl} />
                    </TextContainer>
                </TextAndMediaContent>
            </Wrapper>
        </TextAndMediaContainer>
    );
}

export default TextAndMedia;
