import React, { FC } from 'react';
import { BacktoTop, BottomFooter, FooterContent, FooterDivider, FooterMenuLink, FooterWrapper, LogoContainer, TopFooter } from './style';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { IconSvg, Images } from '../../constants/images';
import { Paragraph } from '../../constants/core.style';

interface footerProp {

}

const Footer: FC<footerProp> = () => {

    const scrollToTopHandle = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    
    return (
        <FooterWrapper>
            <FooterContent>
                <TopFooter>
                    <LogoContainer>
                        <RouterLink to={'/wishlist'}>
                            <img src={Images.Logo} alt="Logo" />
                        </RouterLink>
                    </LogoContainer>
                    <FooterMenuLink>
                        <NavLink to={'/'} >Home</NavLink>
                        <NavLink to={'/about-us'}>About us</NavLink>
                        <NavLink to={'/contact-us'}>Contact us</NavLink>
                    </FooterMenuLink>
                </TopFooter>
            </FooterContent>
            <FooterDivider />
            <FooterContent>
                <BottomFooter>
                    <FooterMenuLink>
                        <Paragraph className='text-md'>©2023 welcare. All rights reserved</Paragraph>
                        <NavLink to={'/about-us'}>Terms and Condition</NavLink>
                        <NavLink to={'/contact-us'}>Privacy Policy</NavLink>
                    </FooterMenuLink>
                    <BacktoTop onClick={scrollToTopHandle}>
                        <Paragraph>Back to Top</Paragraph>
                        <IconSvg.ArrowUpRound/>
                    </BacktoTop>
                </BottomFooter>
            </FooterContent>
        </FooterWrapper>
    )
}

export default Footer;