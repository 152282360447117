import styled from "styled-components";
import { colors } from "../../constants/color";

export const MultiTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 916px;
    margin: auto;
`

export const MultiTextContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primaryColor};
    padding: 100px 0px;
    overflow: hidden;

    h2 {
        color: #fff;
    }

    p {
        color: #fff;
        opacity: 0.5;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    svg {
        position: absolute;
        height: auto;

        &:first-child {
            left: -5%;
            bottom: -50%;
            width: 24%;
        }

        &:not(:first-child) {
            top: -50%;
            right: -20%;
            width: 35%;
        }
    }
`