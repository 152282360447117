import { FC } from "react";
import { Wrapper } from "../../../common/constants/core.style";
import CareerBanner from "../components/careerBanner";
import {
  CareerBannerContainer,
  MainSection,
  MainSectionContent,
} from "./style";
import Signcard from "../components/signCard";

const CareerPage: FC = () => {
  return (
    <>
      <CareerBannerContainer>
        <MainSection>
          <Wrapper>
            <MainSectionContent>
              <CareerBanner />
            </MainSectionContent>
          </Wrapper>
        </MainSection>
      </CareerBannerContainer>
      <MainSection>
          <Wrapper>
            <Signcard/>
          </Wrapper>
        </MainSection>
      
    </>
  );
};

export default CareerPage;
