import styled from "styled-components";
import { Heading1, Wrapper } from "../../../common/constants/core.style";

export const Divider = styled.div`
    width: 100%;
    height: 3px;
    background-color: #6F6F6F;
    opacity: 0.3;
    margin-bottom: 60px;
`

export const CustomerProfileList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 40px 0px;

    span {
        width: 34px;
        height: 34px;
        overflow: hidden;
        border-radius: 100%;
        border: 2px solid #fff; 
        margin-left: -20px;

        &:first-child {
            margin-left: 0px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`

export const RightContent = styled.div`
    width: calc(50%);
    flex: 1;

    p {
        color: #023020;

        &:first-child {
            margin-bottom: 30px;
            font-weight: 500;
        }
    }
`

export const LeftContent = styled.div`
    width: calc(50% - 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h2 {
        ${Heading1}
        padding-right: 42%;
    }
`;

export const AboutSection = styled.section`
    display: flex;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 80px 0px;
    z-index: 0;

    svg {
        width: 300px;
        height: auto;
        position: absolute;
        top: -40px;
        right: -210px;
        z-index: -1;

        &:first-child {
            bottom: -150px;
            left: -150px;
            top: unset;
            right: unset;
        }

    }

    ${Wrapper} {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    @media only screen and (max-width: 1024px) {
        ${LeftContent} {
            h2 {
                padding-right: 20%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        ${LeftContent} {
            width: 100%;
            margin-bottom: 20px;
            h2 {
                padding-right: 5%;
            }
        }
    }
`