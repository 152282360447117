import styled from "styled-components";

export const CreateProfileWrapper = styled.div`
    width: 400px;
    padding: 0px 15px;
    max-width: 100%;
`

export const CreateProfileSection = styled.section`
    width: 100%;
    display: flex;
    padding: 140px 0px;
    justify-content: center;
    align-items: center;
`