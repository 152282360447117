import { FC, SVGProps } from 'react';
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg"
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/left-arrow.svg"
import { ReactComponent as HomeBannerGradient } from "../../assets/images/home-banner-gradient.svg"
import { ReactComponent as WordRoundedShape } from "../../assets/images/word-rounded-shape.svg"
import { ReactComponent as HomeBannerLeftBg } from "../../assets/images/home-left-bg.svg"
import { ReactComponent as HomeButtonShape } from "../../assets/images/home-button-shape.svg"
import { ReactComponent as sliderLeftArrow } from "../../assets/icons/slider-arrow-left.svg"
import { ReactComponent as sliderRightArrow } from "../../assets/icons/slider-arrow-right.svg"
import { ReactComponent as QuoteCircle } from "../../assets/icons/quote-circle-icon.svg"
import { ReactComponent as ArrowUpRound } from "../../assets/icons/Arrow-up-round.svg"
import { ReactComponent as HighlightShape } from "../../assets/icons/highlight_shape.svg"
import { ReactComponent as HighlightShapeInverse } from "../../assets/icons/highlight_shape_inverse.svg"
import { ReactComponent as CircleBgShape } from "../../assets/icons/circle-bg-shape.svg"
import { ReactComponent as CircleBgShapeSmall } from "../../assets/icons/circle-bg-shape-small.svg"
import { ReactComponent as EmailIcon } from "../../assets/icons/email-icon.svg"
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone-icon.svg"
import { ReactComponent as AddressIcon } from "../../assets/icons/address-icon.svg"
import { ReactComponent as ContactFormBg } from "../../assets/images/contact-form-bg.svg"
import { ReactComponent as TickIcon } from "../../assets/icons/tick.svg"
import { ReactComponent as DropDownIcon } from "../../assets/icons/dropdown-icon.svg"
import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon.svg"
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg"
import { ReactComponent as location } from "../../assets/icons/location.svg"
import { ReactComponent as ThreeDots } from "../../assets/icons/three-dots.svg"
import { ReactComponent as Mark } from "../../assets/icons/mark.svg"
import { ReactComponent as SaveIcon } from "../../assets/icons/saved-icon.svg"
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg"
import { ReactComponent as Search } from "../../assets/icons/search.svg"
import { ReactComponent as Registerimg } from "../../assets/images/register.svg"

export const Images: { [key: string]: string } = {
  Logo: require("../../assets/images/welcare-logo.png"),
  HomeBannerRightBg: require('../../assets/images/home-right-bg.png'),
  image_1: require('../../assets/images/image_1.jpg'),
  image_2: require('../../assets/images/image_2.jpg'),
  AboutUsBanner: require('../../assets/images/about-us-banner.jpg'),
  Collage_1: require('../../assets/images/collage_img_1.jpg'),
  Collage_2: require('../../assets/images/collage_img_2.jpg'),
  Collage_3: require('../../assets/images/collage_img_3.jpg'),
  Collage_4: require('../../assets/images/collage_img_4.jpg'),
  Collage_5: require('../../assets/images/collage_img_5.jpg'),
  ContactUs: require('../../assets/images/contact-us-banner.jpg'),
  QR: require('../../assets/images/qr.png'),
};

export const IconSvg: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  ArrowRight: ArrowRightIcon,
  ArrowLeft: ArrowLeftIcon,
  HomeBannerGradient: HomeBannerGradient,
  WordRoundedShape: WordRoundedShape,
  HomeBannerLeftBg: HomeBannerLeftBg,
  HomeButtonShape: HomeButtonShape,
  SliderLeftArrow: sliderLeftArrow,
  SliderRightArrow: sliderRightArrow,
  QuoteCircle: QuoteCircle,
  ArrowUpRound: ArrowUpRound,
  HighlightShape: HighlightShape,
  HighlightShapeInverse: HighlightShapeInverse,
  CircleBgShape: CircleBgShape,
  CircleBgShapeSmall: CircleBgShapeSmall,
  EmailIcon: EmailIcon,
  PhoneIcon: PhoneIcon,
  AddressIcon: AddressIcon,
  ContactFormBg: ContactFormBg,
  TickIcon: TickIcon,
  DropDownIcon: DropDownIcon,
  InfoIcon: InfoIcon,
  CloseIcon: CloseIcon,
  location:location,
  ThreeDots:ThreeDots,
  Mark:Mark,
  SaveIcon:SaveIcon,
  ArrowDown:ArrowDown,
  Search:Search,
  Registerimg:Registerimg,
};