import styled from "styled-components";

export const ModalContent = styled.div`
    padding: 20px;
    width: 100%;
    max-height: calc(100vh - var(--modal-content-header));
    overflow-y: auto;
`

export const ModalFooter = styled.div`
    border-top: 1px solid #D4D4D4;
    padding: 20px;
    width: 100%;
`

export const ModalHeader = styled.div`
    width: 100%;
    color: #001E1D;
    font-weight: 500;
    padding: 20px;
    border-bottom: 1px solid #D4D4D4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 19px;
        color: #001E1D;
        max-width: calc(100% - 20px);
    }

    svg {
        cursor: pointer;
        position: relative;
        width: 24px;
        height: 24px;
        padding: 4px;

        &:after {
            content: '';
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
        }
    }
`

export const ModalContainer = styled.div`
    width: 375px;
    max-width: 100%;
    display: flex;
    border-radius: 8px;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transform: translateY(100%);
    opacity: 0;
    transition: all .5s ease-in-out;

    &:wc-modal-fullwidth {
        width: 100%;
        border-radius: 0px;
    }

    &:wc-modal-small {
        width: 375px;
    }

    &:wc-modal-small {
        width: 720px;
    }

    @media only screen and (max-width: 991px) {
        margin-top: auto;
        width: 100%;
        border-radius: 0px;
    }
`

export const ModalOverlay = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 0%;
    background-color: rgba(10, 10, 31, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.wc-modal-open {
        height: 100%;
        opacity: 1;
        width: 100%;
        background-color: rgba(10, 10, 31, 0.4);

        ${ModalContainer} {
            transform: translateY(0%);
            opacity: 1;
        }
    }
`
   