import styled from "styled-components"

export const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 6%;
    }

    > * {
        flex: 1;  
        width: 100%; 
    }

    > {
        label {
            font-weight: 600;
            font-size: 14px;
            color: #023020;
            margin-bottom: 14px;
        }
    }
`

export const FormItemHalf = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 6%;
    }

    > * {
        flex: 1;  
        width: 100%; 
    }

    > {
        label {
            font-weight: 600;
            font-size: 14px;
            color: #023020;
            margin-bottom: 14px;
        }

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      > {
        &:not(:last-child) {
          margin-right: 0px;
        }
      }
    }
`

export const UserDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    // overflow: hidden;

    > {
        h3 {
            font-size: 22px;
            margin-bottom: 24px;    
        }

        h4 {
            margin-bottom: 24px;    
            font-size: 18px;
        }

        p {
            margin-bottom: 24px;    
            font-size: 14px;
        }
    }
`