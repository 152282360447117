import React, { FC, useState, useRef, useEffect } from "react";
import { SelectContainer, SelectDropdown, SelectDropdownItem, SelectLabel, SelectSelectedLabel } from "./style";
import { IconSvg } from "../../constants/images";

type SelectOption = {
    label: string;
    value: string;
};

interface CustomSelectProps {
    label?: string;
    options: SelectOption[];
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    orientation?: 'vertical' | 'horizontal';
    size?: 'small' | 'large';
    required?: boolean;
    disabled?: boolean;
}

const CustomSelect: FC<CustomSelectProps> = (props) => {
    const {
        label,
        options,
        value,
        onChange,
        placeholder = "Select an option",
        orientation = 'vertical',
        size = 'small',
        required = false,
        disabled = false,
    } = props;

    const [showDropdown, setShowDropdown] = useState(false);
    const selectContainerRef = useRef<HTMLDivElement>(null);

    function useOnClickOutside<T extends HTMLElement = HTMLElement>(
        ref: React.RefObject<T>,
        handler: (event: MouseEvent | TouchEvent) => void
    ): void {
        useEffect(() => {
            const listener = (event: MouseEvent | TouchEvent) => {
                if (!ref.current || ref.current.contains(event.target as Node)) {
                    return;
                }
                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        }, [ref, handler]);
    }

    useOnClickOutside(selectContainerRef, () => setShowDropdown(false));

    const toggleDropdown = () => !disabled ? setShowDropdown(!showDropdown) : null;

    const handleOptionClick = (optionValue: string) => {
        if (!disabled) {
            onChange(optionValue);
            toggleDropdown();
        }
    };

    const selectedOption = options.find(option => option.value === value) || { label: placeholder, value: '' };

    return (
        <SelectContainer ref={selectContainerRef} className={`wc-select-${orientation} wc-select-${size}`}>
            <SelectLabel>{label}{required && <span>*</span>}</SelectLabel>
            <SelectSelectedLabel onClick={toggleDropdown} className={` ${disabled ? 'wc-select-disabled' : ''} ${showDropdown ? 'wc-expanded' : 'wc-collapsed'}`}>
                <span>{selectedOption.label}</span>
                <IconSvg.DropDownIcon />
            </SelectSelectedLabel>
            {showDropdown && (
                <SelectDropdown>
                    {options.map(option => (
                        <SelectDropdownItem
                            key={option.value}
                            className={selectedOption.label == option.label ? 'wc-active' : ''}
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {option.label}
                        </SelectDropdownItem>
                    ))}
                </SelectDropdown>
            )}
        </SelectContainer>
    );
};

export default CustomSelect;
