import styled from "styled-components";

export const TextAreacontainer = styled.div`
    display: flex;

    label {
        span {
            color: #FF0054;
        }
    }

    textarea {
        width: 100%;
        background-color: #fff;
        border: 1px solid #7070704d;
        height: 120px;
        border-radius: 6px;
        padding: 10px;
        overflow: hidden;
        font-size: 14px;
        color: #707070;
        resize: none;
    }

    &.wc-input-horizontal {
        flex-direction: row;

        label {
            margin-right: 8px;
        }
    }

    &.wc-input-vertical {
        flex-direction: column;

        label {
            margin-bottom: 8px;
        }
    }

    &.wc-input-small {
        textarea {
            height: 80px;
        }
        label {
            font-weight: 600;
            font-size: 14px;
            color: #023020;
        }
    }

    &.wc-input-large {
        textarea {
            height: 120px;
            font-size: 20px
        }
        label {
            font-weight: 400;
            font-size: 20px;
            color: #2D3035;
        }
    }
`