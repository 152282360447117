import styled from "styled-components";
import { Wrapper } from "../../../../common/constants/core.style";
import { colors } from "../../../../common/constants/color";

export const InfoHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;

    svg {
        height: 18px;
        width: auto;
        margin-right: 12px;
    }

    p {
        font-weight: 600;
        text-transform: capitalize;
        color: #000;
    }
`

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    
    > {
        p {
            margin-bottom: 6px;
        }

        a {
            margin-bottom: 6px;
        }
    }
`

export const InfoItemList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    padding-left: 10%;
`

export const MainSectionContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: calc(100%);
`;

export const LeftContent = styled.div`
    display: flex;
    width: 640px;
    max-width: calc(100%);
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    img {
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
    }

    h2 {
        position: absolute;
        font-weight: 700;
        font-size: 44px;
        color: #fff;
        left: 40px;
        top: 40px;
        padding-right: 20%;
    }
`;

export const RightContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
`;

export const MainSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 950px;
    padding: 0px 15px;
    padding-top: clamp(100px, 10% , 0px);
    padding-bottom: 100px;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    > {
        img {
            width: 100%;
        }

        h1 {
            font-weight: 600;
            font-size: 54;
            margin-bottom: 20px;
            text-align: center;
        }

        p {
            margin-bottom: 50px;
            text-align: center;
        }
    }
`;

export const MainSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow: hidden;

    > {
        svg {
            position: absolute;
            width: 300px;
            height: auto;
            top: 40px;
            right: -150px;

            &:first-child {
                left: -150px;
                bottom: -150px;
                top: unset;
                right: unset;
            }
        }
    }

    @media only screen and (max-width: 640px) {
        ${InfoItemList} {
            padding-left: 0px;
            padding-top: 20px;
        }
    }
`
