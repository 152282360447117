import styled from "styled-components";
import { colors } from "../../../common/constants/color";
import { Heading1, Wrapper } from "../../../common/constants/core.style";

export const Divider = styled.div`
    width: 100%;
    height: 3px;
    background-color: #6F6F6F;
    opacity: 0.3;
    margin-bottom: 60px;
`

export const CustomerProfileList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 40px 0px;

    span {
        width: 34px;
        height: 34px;
        overflow: hidden;
        border-radius: 100%;
        border: 2px solid #fff; 
        margin-left: -20px;

        &:first-child {
            margin-left: 0px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`

export const RightContent = styled.div`
    width: calc(50%);
    flex: 1;
    display:flex;
    flex-wrap:wrap;

    p {
        color: #023020;
        background-color:${colors.primaryColor};
        padding:10px;
        border:solid 1px #ccc;
        width:calc(50% - 20px);
        border-radius:6px;
        margin:10px;
        text-align:center;
        color:#fff;

    }
`

export const LeftContent = styled.div`
    width: calc(50% - 30px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h2 {
        ${Heading1}
        padding-right: 42%;
    }
`;

export const AboutSection = styled.section`
    display: flex;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 80px 0px;
    z-index: 0;

    svg {
        width: 300px;
        height: auto;
        position: absolute;
        top: -40px;
        right: -210px;
        z-index: -1;

        &:first-child {
            bottom: -150px;
            left: -150px;
            top: unset;
            right: unset;
        }

    }

    ${Wrapper} {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    @media only screen and (max-width: 1024px) {
        ${LeftContent} {
            h2 {
                padding-right: 20%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        ${LeftContent} {
            width: 100%;
            margin-bottom: 20px;
            h2 {
                padding-right: 5%;
            }
        }
    }
`
export const TextContainer = styled.div`
    display : flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    padding-bottom:60px;
    flex-wrap: wrap;

    h2 {
        ${Heading1}
        width: calc(50% - 30px);
        padding-right: 9%;
    }

    p {
        width: calc(50%);
        flex: 1;
    }

    @media only screen and (max-width: 768px) {
        h2 {
            width: 100%;
            margin-bottom: 20px;
        }
    }
`
export const ServicePointsContainer = styled.div`
    
`
export const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 4px 0px;

    span {
        height: 50px;
        aspect-ratio: 1;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        background-color: ${colors.primaryColor};
        color: #fff;
        margin-right: 30px;
    }

    @media only screen and ( max-width: 1024px ) {
        margin: 15px 0px;
    }
`

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0px;
`
export const ListMain = styled.div`
    display:flex;
    justify-content:space-between;
`