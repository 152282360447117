import styled from "styled-components";

export const FormAction = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
`

export const FormItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 6%;
    }

    > * {
        flex: 1;

        &:first-child:not(:last-child) {
            margin-right: 20px;
        }
    }
`

export const FormItemHalf = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 6%;
    }

    > * {
        flex: 1;  
        width: 100%; 
    }

    > {
        label {
            font-weight: 600;
            font-size: 14px;
            color: #023020;
            margin-bottom: 14px;
        }

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      > {
          &:not(:last-child) {
            margin-bottom: 15px;
            margin-right: 0px;
        }
      }
    }
`

export const ContactFormCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    max-width: 560px;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
`