import styled from "styled-components";
import { colors } from "../../constants/color";

export const BannerTitle = styled.p`
    color: #023020;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
`

export const BannerContent = styled.div`
    width: calc(100% - 25px);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    * {
        &:not(${BannerTitle}) {
            width: 100%;
            font-size: 12px;
            color: #454C56;
        }
    }
`

export const BannerIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 5px;
`

export const BannerCardContainer = styled.div`
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #F3F5F7;

    &.wc-banner-critical {
        background-color: #FEEEEF;
        border-color : #CB98A6;

        svg {
            fill: #FEEEEF;
            path {
                fill: #FEEEEF;
            }
        }
    }

    &.wc-banner-info {
        background-color: #fff;
        border-color : ${colors.primaryColor};

        svg {
            fill: ${colors.primaryColor};
            path {
                fill: ${colors.primaryColor};
            }
        }
    }

    &.wc-banner-success {
        background-color: #E4F7E6;
        border-color : #1F662C;

        svg {
            fill: #1F662C;
            path {
                fill: #1F662C;
            }
        }
    }
`