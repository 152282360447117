import styled from 'styled-components';

export const SubMenuListItem = styled.li`
    display: flex;
    padding: 5px;

    a {
        font-size: 18px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 600;
        height: 100%;
        transition: all 0.2s ease-in-out;

        &:hover {
            color: #2058D1;
            animation : leftRight 0.8s;
        }

        @keyframes leftRight {
            0%   { transform: translateX(0); opacity: 1; }
            20%  { transform: translateX(5px); opacity: 0.9; }
            40%  { transform: translateX(-5px); opacity: 0.9; }
            60%  { transform: translateX(5px); opacity: 0.9; }
            100% { transform: translateX(0); opacity: 1; }
        }
    }
`

export const SubMenuList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
    background: rgb(255 255 255 / 80%);
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 12px 24px rgba(0,  0, 0, 0.1);
    padding: 15px;
    position: absolute;
    top: calc(100% + 9px);
    right: 0px;
    opacity: 0px;
    visibility: hidden;
`

export const SubMenu = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
        background-color: #fff;
        ${SubMenuList} {
            opacity: 1;
            visibility: visible;
        }
    }

    span {
        line-height: 10px;
         svg {
            margin-left: 5px;
            display: flex;
            align-items: center;

            path {
                stroke: #023020;
            }
         }
    }
`

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1240px;
    width: 100%;

    @media only screen and (max-width: 1240px) {
        padding-left: 15px;
        padding-right: 15px;
    }
`;


export const LogoContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 66px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 66px;

        img {
            height: 100%;
            width: auto;
        }
    }
`;

export const MenuItem = styled.div`
    height: 100%;
    margin-right: 10%;
    cursor: pointer;
`

export const MenuList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    flex: 1;
    max-width: 640px;

    @keyframes updown {
        0%   { transform: translateY(0); opacity: 1; }
        20%  { transform: translateY(8px); opacity: 0.9; }
        40%  { transform: translateY(-8px); opacity: 0.9; }
        60%  { transform: translateY(8px); opacity: 0.9; }
        100% { transform: translateY(0); opacity: 1; }
    }

    ${MenuItem} > {

        a {
            font-size: 18px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-weight: 600;
            height: 100%;
            transition: all 0.2s ease-in-out;
    
            &:not(.active):hover {
                color: #2058D1;
                animation: updown 0.8s;
            }
            
    
            &:after {
                content: '';
                position: absolute;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
                width: 0%;
                height: 2px;
                background-color: #2058D1;
                transition: all 0.4s ease-in-out;
            }
    
            &.active {
                color: #2058D1;
            }
        }
    }


    ${SubMenu}{
        span {
            font-size: 18px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-weight: 600;
            height: 100%;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            color: #023020;

            &:not(.active):hover {
                color: #2058D1;
                animation: updown 0.8s;

                svg {
                    path {
                        stroke: #2058D1;
                    }
                }
            }
            

            &:after {
                content: '';
                position: absolute;
                bottom: -8px;
                left: 50%;
                transform: translateX(-50%);
                width: 0%;
                height: 2px;
                background-color: #2058D1;
                transition: all 0.4s ease-in-out;
            }

            &.active {
                color: #2058D1;
            }
        }
    }
`;

export const HamBurgerMenu = styled.div`
    display:flex;
    flex-direction:column;
    width:40px;
    cursor:pointer;
    margin-right: 20px;
    position: relative;
    z-index: 1;
   
    span {
        background: #023020;
        border-radius:10px;
        height:4px;
        margin: 4px 0;
        transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

        &:nth-of-type(1){
            width:50%;
            
        }
        
        &:nth-of-type(2){
            width:100%;
        }
        
        &:nth-of-type(3){
            width:75%;
        
        }
   }
   
    div {
        display: none;
    }
   
    div.wc-active ~ span:nth-of-type(1){
        transform-origin: bottom;
        transform: rotatez(45deg) translate(5px,1px)
    }

    div.wc-active ~ span:nth-of-type(2){
        transform-origin: top;
        transform: rotatez(-45deg)
    }

    div.wc-active ~ span:nth-of-type(3){
        transform-origin: bottom;
        width: 50%;
        transform: translate(17px,-6px) rotatez(45deg);
    }

    @media only screen and (min-width: 1024px) {
        display: none;
    }
   
`;

export const MenuListMobile = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 0%;
    opacity: 0;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    transition: width 0.3s ease-in-out;
    padding-left: 30px;
    
    &.menu-open {
        width: 75%;
        opacity: 1;

        > * {
            &:first-child {
             margin-top: 90px;
            }
        }

        ${MenuItem} {
            height: auto;
            padding: 10px;
            padding-left: 0px;

            ${SubMenu} {
                height: auto;

                span {
                    color: #023020;
                    margin-right: 4px;
                }
            }

            ${ SubMenuList} {
                background: transparent;
                box-shadow: none;
                height: auto;
                left: 0px;
                opacity: 1;
                visibility: visible;
                padding-top: 5px;
                padding-left: 4px;
                padding-bottom: 5px;

                a {
                    font-size: 16px;
                    font-weight: normal;
                }

                li {
                    flex: 0;
                }
            }
        }

    }

    > a {
        padding: 15px 0px;

        &:first-child {
            margin-top: 90px;
        }

    }
`;

export const BackDrop = styled.div`
    position: absolute;
    right: calc(100% / -3);
    background-color: #000;
    opacity: 0.4;
    width: calc(100% / 3);
    height: 100%;
`;

export const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 8px;
    position: fixed;
    top: 0px;
    z-index: 999;
    transition: all 0.4s ease-in-out;

    &.wc-scrolled {
        background: #fff;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);

        ${MenuList} {
            a {
                &.active {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        ${SubMenuList} {
            background: #fff;
        }
    }
`;