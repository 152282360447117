import React, { FC } from "react";
import { MultiTextContainer, MultiTextWrapper } from "./style";
import { Head2, Paragraph } from "../../constants/core.style";
import Button from "../button";
import { IconSvg } from "../../constants/images";

interface MultiTextProps {
    heading?: string;
    description?: string;   
    buttonLabel?: string;
    buttonUrl?: string;
}

const MultiText:FC<MultiTextProps> = ( props ) => {
    const {
        heading,
        description,
        buttonLabel,
        buttonUrl = '#'
    } = props;

    return (
        <MultiTextContainer>
            <IconSvg.CircleBgShapeSmall/>
            <IconSvg.CircleBgShape/>
            <MultiTextWrapper>
                <Head2>{heading}</Head2>
                {description ? (
                    <Paragraph className="text-md">{description}</Paragraph>
                ) : null }
                {buttonLabel && buttonUrl ? (
                    <Button
                        label={buttonLabel}
                        url={buttonUrl}
                        variant='light'
                    />
                ) : null}
            </MultiTextWrapper>
        </MultiTextContainer>
    )
}

export default MultiText;