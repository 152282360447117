import React, { FC, useState, useEffect } from "react";
import { InputContainer } from "./style";

interface CustomInputProps {
    label?: string;
    required?: boolean;
    type: 'text' | 'password' | 'number' | 'email' | 'tel';
    id: string;
    orientation?: 'vertical' | 'horizontal';
    size?: 'small' | 'large';
    showValidationMessage?: boolean;
    errorMessage?: string;
    successMessage?: string;
    placeholder?: string;
    disabled?: boolean;
    inputValue? : any;
    error?: any;
    onChange?: (value: string) => any;
}

const CustomInput: FC<CustomInputProps> = (props) => {
    const {
        label,
        required = false,
        type = 'text',
        id,
        orientation = 'vertical',
        size = 'small',
        showValidationMessage = false,
        errorMessage = 'Error',
        successMessage = 'Success',
        placeholder,
        disabled = false,
        inputValue = '',
        error = '',
        onChange
    } = props;

    const [value, setValue] = useState(inputValue);
    const [validationMessage, setValidationMessage] = useState('');

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

    const inputHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        if (type === 'number' && isNaN(Number(newValue))) {
            setValidationMessage('Invalid Input');
        } else {
            setValidationMessage(successMessage);
        }

        setValue(newValue);

        onChange && onChange(newValue);
    };

    const invalid = !!validationMessage;

    return (
        <InputContainer className={`wc-input-${orientation} wc-input-${size}`}>
            {label ? (
                <label htmlFor={id}>{label}
                    {required && <span>*</span>}
                </label>
            ): null}
            <input
                type={type}
                value={value}
                onChange={inputHandle}
                id={id}
                aria-describedby={`${id}-error`}
                aria-labelledby={label ? id : undefined}
                aria-invalid={invalid ? 'true' : undefined}
                placeholder={placeholder}
                disabled={disabled}
            />
            {showValidationMessage && invalid && <div id={`${id}-error`} role="alert">{validationMessage}</div>}
            {error && <div id={`${id}-error-message`} style={{ color: 'red' }} role="alert">{error}</div>}
        </InputContainer>
    );
};

export default CustomInput;
