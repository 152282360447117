import styled from "styled-components";
import { colors } from "../../constants/color";

export const ToastContainer =styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0px;
    font-size: 16px;
    max-width: 100%;
    width: 0%;
    transition: all 0.8s ease-in-out;
    overflow: hidden;
    padding: 0px;

    z-index: 999;
    color: #fff;

    span {
        white-space: nowrap;
        width: auto;
        background-color: #2a2a2a;
        padding: 10px;
        border-radius: 8px;
        transition: all 0.4s ease-in-out;
        box-shadow: 0 0 6px 0px #00000061;
    }

    &.active {
        width: 100%;
        padding: 10px;
    }
`