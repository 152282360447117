import React, { FC } from "react";
import {
  AboutSection,
  LeftContent,
  CustomerProfileList,
  RightContent,
  Divider,

} from "./style";
import HeroSection from "../components/mainSection";
import {
  Head2,
  Head4,
  Paragraph,
  Wrapper,
} from "../../../common/constants/core.style";
import { IconSvg, Images } from "../../../common/constants/images";
import PhotoCollage from "../components/PhotoCollage";
import CardListing from "../components/cardListing";
import ServiceCard from "../../../common/components/serviceCard";

const HealthSupport: FC = () => {
  return (
    <>
      <HeroSection title={"Mental health & wellbeing "} description={"Our commitment lies in offering complete mental health and well-being services designed with the needs of senior citizens in mind. We are aware of the challenges and requirements seniors have in preserving their mental and emotional well-being as they get older. Welcare’s team of certified and experienced therapists is dedicated to providing custom support, guidance, and companionship to enhance emotional health, interpersonal relationships, and general welfare among the elderly. Our mission is to enable elderly individuals to have happy, purposeful, and peaceful lives. Let us be your partner in making mental health and well-being a priority."} titleMain={"support"} />
      <AboutSection>
        <IconSvg.CircleBgShapeSmall />
        <IconSvg.CircleBgShape />
        <Wrapper>
          <Divider />
          <LeftContent>
            <Head2>
            We  <strong>provide</strong>
            </Head2>
            <CustomerProfileList>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
            </CustomerProfileList>
          </LeftContent>
          <RightContent>
            <Paragraph className="text-lg">Alcohol and drug support</Paragraph>
            <Paragraph className="text-lg">Behaviour support specialist advice</Paragraph>
            <Paragraph className="text-lg">Psychology and Well-being support</Paragraph>
            <Paragraph className="text-lg">
            Autism support services
            </Paragraph>
          </RightContent>
        </Wrapper>
      </AboutSection>
      <PhotoCollage />

      <CardListing
        heading="Alcohol and drug support"
        description="Isolation, lack of mobility, depression, and loneliness are some of the factors that contribute to drug or alochol addiction in the elderly. This addiction can be difficult to deal with because of age. The way an older person will be able to handle rehab is quite different and hence monitoring needs to be done with medical experts who are trained to treat the elderly. We know just that and are trained to provide you with the best service to bring back your health and ensure your well-being.
        "
      >
        <Head4>Our Services</Head4>
        <ServiceCard heading="Counseling" />
        <ServiceCard heading="Detoxification Programs" />
        <ServiceCard heading="Rehabilitation" />
        <ServiceCard heading="Aftercare and Relapse Prevention" />
        <ServiceCard heading="Family Support and Education" />
        
      </CardListing>

      <CardListing
        heading="Behaviour support specialist advice"
        description="Welcare provide support and training for Positive Behaviour Support (PBS). PBS is a customised strategy that considers the unique requirements of every individual. It involves getting a deep grasp of the senior's behaviour and determining the social, mental, and physical aspects that are sustaining it. After gathering the data about the aspects, a plan is created to address the behaviour, which will involve teaching the person new skills in addition to making adjustments to their surroundings or routine.
        "
      >
        <Head4>Our Services</Head4>
        <ServiceCard heading="Assessment and Functional Behavior Analysis (FBA)" />
        <ServiceCard heading="Individualized Behavior Support Plans (BSP)" />
        <ServiceCard heading="Consultation" />
        <ServiceCard heading="Behavioral Interventions and Strategies" />
        <ServiceCard heading="Training and Development" />

        <ServiceCard heading="Data Analysis" />
        <ServiceCard heading="Transition Planning" />
        <ServiceCard heading="Crisis Intervention" />
        <ServiceCard heading="Advocacy and Support" />
        <ServiceCard heading="Program Development and Evaluation" />
        
      </CardListing>

      <CardListing
        heading="Psychology and Well-being"
        description="Our team is well-trained to assess and treat a wide range of mental health problems among the elderly. We have extensive experience performing psycho-diagnostic evaluations, and we presently provide these services to several funders. We offer prompt, high-quality, culturally relevant assessments to people who come in with a variety of requirements, many of which are extremely complex.

        "
      >
        <Head4>Our Services</Head4>
        <ServiceCard heading="Individual Counseling" />
        <ServiceCard heading="Family Therapy and Support" />
        <ServiceCard heading="Cognitive Behavioral Therapy (CBT)" />
        <ServiceCard heading="Stress Management training" />
        <ServiceCard heading="Anxiety & Depression Treatment" />
        <ServiceCard heading="Trauma and PTSD Therapy" />
        <ServiceCard heading="Anger Management therapy" />
        <ServiceCard heading="Psychological Assessment and Evaluation" />
        <ServiceCard heading="Mindfulness and Meditation Training" />
        <ServiceCard heading="Wellness Programs" />
        <ServiceCard heading="Psychoeducation" />
        <ServiceCard heading="Sleep Therapy" />
        <ServiceCard heading="Insomnia Treatment" />
        
      </CardListing>

      <CardListing
        heading="Autism support services"
        description="We are aware that autism spectrum disorder (ASD) is a lifelong condition, and hence, we provide seniors with autism with specialized care that is compassionate and tailored to their needs. Welcare is committed to providing a variety of services, such as social skills training, behavioral therapy, sensory integration approaches, and transition planning, to assist senior people with autism in leading relaxed lives.
        "
      >
        <Head4>Our Services</Head4>
        <ServiceCard heading="Diagnostic Assessment" />
        <ServiceCard heading="Behavioral Support" />
        <ServiceCard heading="Social Skills Training" />
        <ServiceCard heading="Sensory Integration Therapy" />
        <ServiceCard heading="Speech Therapy" />
        <ServiceCard heading="Assistive Technology" />
        <ServiceCard heading="Caregiver Support" />
        <ServiceCard heading="Residential Support" />
        <ServiceCard heading="Recreation Programs" />
      </CardListing>
    </>
  );
};

export default HealthSupport;
