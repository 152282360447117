import styled from "styled-components";
import { colors } from "../../constants/color";

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 33px;
    border-radius: 6px;
    width: auto;
    height: 48px;
    transition: all 0.3s ease-in-out;
    position: relative;

    &:hover {
        box-shadow: 3px 4px 6px #aeb2e4a3;
    }

    button {
        height: calc(100% + 30px);
        width: calc(100% + 66px);
        margin: -15px -33px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        cursor: pointer;
        font-size: 18px;
    }
    
    &.wc-btn-primary {
        background-color: ${colors.primaryColor};
        color: #fff;
        a, button {
            color: #fff;
        }
    }

    &.wc-btn-secondary {
        background-color: ${colors.secondaryColor};
        color: #fff;
        a {
            color: #fff;
        }
    }

    &.wc-btn-outline {
        background-color: transparent;
        color: #fff;
        border:solid 1px #023020;
        a {
            color: #023020;
        }
    }

    &.wc-btn-light {
        background-color: #fff;
        color: ${colors.primaryColor};
        a {
            color: ${colors.primaryColor};
        }
    }

    &.wc-btn-dark {
        background-color: #000;
        color: #fff;
        a {
            color: #fff;
        }
    }

    &.wc-btn-plain {
        background-color: transparent;
        color: ${colors.primaryColor};
        a {
            color: ${colors.primaryColor};
        }
    }

    &.w-100 {
        width: 100%;
        flex: 1;
    }

    .button--loading .button__loader {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-color: rgb(255 255 255 / 47%);
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
    }

    @keyframes button-loading-spinner {
        from {
            transform: rotate(0turn);
        }
        to {
            transform: rotate(1turn);
        }
    }

`;