
import React , { FC } from "react";
import { CardContainer, } from "./style";
import { Head4, Paragraph,} from "../../constants/core.style";

interface ServiceCardProps {
  heading: String;
}

const ServiceCard: FC<ServiceCardProps> = (props) => {
  const {
    heading,
  } = props

  return(
    <CardContainer>
        <Paragraph className="heading ">{heading}</Paragraph>
    </CardContainer>
  )
}

export default ServiceCard;