import React, { FC, useEffect, useState } from 'react';
import { BackDrop, HamBurgerMenu, HeaderContent, HeaderWrapper, LogoContainer, MenuList, MenuListMobile, SubMenu, SubMenuList, SubMenuListItem, MenuItem } from './style';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { IconSvg, Images } from '../../constants/images';
import Button from '../button';

interface MenuItem {
    name: string;
    path?: string;
    subMenu?: MenuItem[];
}

interface HeaderProp {
    menuList?: MenuItem[];
    buttonLabel?: string;
    buttonLink?: string;
}

const Header: FC<HeaderProp> = (props) => {

    const {
        menuList,
        buttonLabel,
        buttonLink,
    } = props

    const [scrolled, setScrolled] = useState<number>(0);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [menuStatus, setMenuStatus] = useState<boolean>(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY);
        };

        window.addEventListener('resize', () => setWindowWidth(window.innerWidth));

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const menuHandle = () => {
        setMenuStatus(!menuStatus);
    }

    const renderSubMenu = (subMenu: MenuItem[]) => {
        return (
            <SubMenuList>
                {subMenu.map((subItem, index) => (
                    <SubMenuListItem key={index}>
                        {subItem.path ? (
                            <NavLink to={subItem.path}>{subItem.name}</NavLink>
                        ) : (
                            <span>{subItem.name}</span>
                        )}
                    </SubMenuListItem>
                ))}
            </SubMenuList>
        );
    };


    return (
        <HeaderWrapper className={scrolled > 10 ? 'wc-scrolled' : ''}>
            <HeaderContent>
                {menuList ? (
                    <HamBurgerMenu onClick={menuHandle}>
                        <div className={menuStatus ? 'wc-active' : 'wc-inactive'}></div>
                        <span></span>
                        <span></span>
                        <span></span>
                    </HamBurgerMenu>
                ) : null}
                <LogoContainer>
                    <RouterLink to={'/wishlist'}>
                        <img src={Images.Logo} alt="Logo" />
                    </RouterLink>
                </LogoContainer>
                {menuList ? (
                    windowWidth > 1024 ? (
                        <MenuList>
                            {menuList?.map((item, index) => (
                                <MenuItem key={index}>
                                    {item.path ? (
                                        <NavLink to={item.path}>{item.name}</NavLink>
                                    ) : (
                                        <SubMenu>
                                            <span>{item.name}
                                            <IconSvg.DropDownIcon />
                                            </span>
                                            {item.subMenu && renderSubMenu(item.subMenu)}
                                        </SubMenu>
                                    )}
                                </MenuItem>
                            ))}

                        </MenuList>
                    ) : (
                        <MenuListMobile className={menuStatus ? 'menu-open' : 'menu-close'}>
                            {menuList?.map((item, index) => (
                                <MenuItem key={index}>
                                    {item.path !== undefined ? (
                                        <NavLink to={item.path}>{item.name}</NavLink>
                                    ) : (
                                        <SubMenu>
                                            <span>{item.name}</span>
                                            <IconSvg.DropDownIcon />
                                            {item.subMenu && renderSubMenu(item.subMenu)}
                                        </SubMenu>
                                    )}
                                </MenuItem>
                            ))}
                            <BackDrop onClick={menuHandle} />
                        </MenuListMobile>
                    )
                ) : null}
                {buttonLabel || buttonLink ? (
                    <Button
                        label={buttonLabel}
                        url={buttonLink || '#'}
                        variant='primary'
                    />
                ) : null}
            </HeaderContent>
        </HeaderWrapper>
    )
}

export default Header;
