import React , { FC } from "react";
import { CardContainer, CardLink, PseudoLink } from "./style";
import { Head3, Paragraph } from "../../constants/core.style";
import { IconSvg } from "../../constants/images";

interface CardProps {
  heading: String;
  description?: String;
  url: string;
}

const Card: FC<CardProps> = (props) => {
  const {
    heading,
    description,
    url = '#',
  } = props

  return(
    <CardContainer>
      <CardLink href={url}>
        <Head3 className="heading h4">{heading}</Head3>
      </CardLink>
      <Paragraph className="description">{description}</Paragraph>
      <PseudoLink>
        Apply Now
        <IconSvg.ArrowRight />
      </PseudoLink>
    </CardContainer>
  )
}

export default Card;