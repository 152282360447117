import styled from 'styled-components';

export const SearchJobWrapper = styled.div`
  display: flex;
  align-items: center;
  width:100%;
  margin-bottom:50px;
  background-color:#fff;
  padding:10px 20px;
  padding-left:0px;
  border-radius:6px;
  @media only screen and (max-width: 991px) {
    flex-direction:column;
    margin-top:16px;
    padding:10px 10px;
    padding-left:10px;
  }
`;

export const SearchInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex:1;
  &:nth-child(2){
    .search-input{
      border-right:none;
    }
  }
  @media only screen and (max-width: 991px) {
    width:100%;
      .search-input{
        border-right:none;
    }
  }
 

  .search-icon{
    position: absolute;
  left: 20px;
  }
`;



export const InputField = styled.input`
  padding:18px 20px;
  padding-left: 46px;
  border: none;
  height: 40px;
  width: 100%;
  font-size: 14px;
  border-right:solid 1.5px #707070;
  
  &:focus-visible{
    outline:none;
  }

`;

export const DropdownWrapper = styled.div`
  position: absolute;
  right:20px;
`;

export const SelectedOption = styled.div`
  cursor: pointer;
  background-color:;
  border:none;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  position:absolute;
  right:20px;

`;

export const Options = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width:100%;
  z-index: 10000;
  background-color: #fff;
  border-top: none;
  border-radius: 0 0 5px 5px;
  font-size:14px;
  box-shadow:0px 20px 17px -18px #ccc;
`;

export const OptionItem = styled.div`
  padding: 8px 10px;
  cursor: pointer;
`;

export const SearchButton = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }
`;
export const SearchBtnContainer = styled.div`
  margin-left:10px;
  @media only screen and (max-width: 991px) {
    margin-top:10px;
    width:100%;
    margin-right:10px;
  }
`