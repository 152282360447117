import React, { FC } from "react";
import { PhotoCollageContainer, PhotoCollageWrapper, TextContainer } from "./style";
import { Images } from "../../../../common/constants/images";
import { Head2, Paragraph } from "../../../../common/constants/core.style";

interface PhotoCollageProps {

}

const PhotoCollage: FC<PhotoCollageProps> = () => {
    return (
        <PhotoCollageWrapper>
            <PhotoCollageContainer>
                <img src={Images.Collage_1} alt="" />
                <img src={Images.Collage_2} alt="" />
                <img src={Images.Collage_3} alt="" />
                <img src={Images.Collage_4} alt="" />
                <img src={Images.Collage_5} alt="" />
            </PhotoCollageContainer>
        </PhotoCollageWrapper>
    )
}

export default PhotoCollage;