import React, { FC } from "react";
import JobSearch from "../../../../common/components/search";
import {
  Head2,
  Paragraph,
} from "../../../../common/constants/core.style";
import {
  TextContent,

} from "./style";

interface CareerBannerProps {}

const CareerBanner: FC<CareerBannerProps> = () => {
  return (
    <>
          <TextContent>
            <Head2 className="heading">Find your next career today within .</Head2>
            <Paragraph className="text-md">
            It is a long established fact that a reader will be distracted by the 
readable content of a page when looking at its layout. The point of using.
            </Paragraph>
          </TextContent>
          <JobSearch onSearch={function (query: string, category: string): void {
        throw new Error("Function not implemented.");
      } } />
      </>
  );
};

export default CareerBanner;
