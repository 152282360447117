import styled from "styled-components";

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const FormSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    max-width: 1100px;
    padding: 0px 15px;

    ${TextContainer} {
        max-width: calc(100% - 560px);
        padding-right: 8%;

        h2 {
            margin-bottom: 20px;
        }
    }

    @media only screen and (max-width: 991px) {
        ${TextContainer} {
            width: 100%;
            max-width: 100%;
            padding-right: 0px;
            margin-bottom: 20px;
        }
    }
`

export const FormSection = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F9FAFC;
    position: relative;
    z-index: 0;
    padding: 10% 0%;

    > {
        svg {
            position:absolute;
            width: 100%;
            height: auto;
            bottom: 0px;
            left: 0px;
            z-index: -1;
        }
    }
`