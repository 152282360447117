import { createGlobalStyle } from "styled-components";
import { Heading2, Heading3, Heading4 } from "./core.style";

export const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body{
    font-size: 16px;
    color: #6F6F6F;
  }

  a {
    text-decoration: none;
    color: #023020;
  }

  h1,h2,h3,h4,h5,h6 {
    color: #023020;
    text-transform: capitalize;
  }

  ::placeholder {
    color: #C9CFCF;
  }

  .h2{
    ${Heading2}
  }

  .h3{
    ${Heading3}
  }
  
  .h4{
    ${Heading4}
  }
`