import React, { useState } from 'react';
import { IconSvg } from '../../constants/images';
import Button from '../button';
import {InputField, OptionItem, Options, SearchBtnContainer, SearchInput, SearchJobWrapper, SelectedOption } from './style';

interface SearchProps {
  onSearch: (query: string, category: string) => void;
}

const JobSearch: React.FC<SearchProps> = ({ onSearch }) => {
  const [searchQueryJob, setSearchQueryJob] = useState<string>('');
  const [searchQueryLocation, setSearchQueryLocation] = useState<string>('');
  const [selectedOptionJob, setSelectedOptionJob] = useState<string>('');
  const [selectedOptionLocation, setSelectedOptionLocation] = useState<string>('');
  const [isOpenJob, setIsOpenJob] = useState<boolean>(false);
  const [isOpenLocation, setIsOpenLocation] = useState<boolean>(false);

  const handleInputChangeJob = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQueryJob(event.target.value);
  };

  const handleInputChangeLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQueryLocation(event.target.value);
  };

  const handleOptionChangeJob = (option: string) => {
    setSelectedOptionJob(option);
    setIsOpenJob(false);
  };

  const handleOptionChangeLocation = (option: string) => {
    setSelectedOptionLocation(option);
    setIsOpenLocation(false);
  };

  return (
    <SearchJobWrapper >
      <SearchInput>
        <IconSvg.Search className='search-icon'/>
        <InputField
          type="text"
          placeholder="What Job title, Hobbies, Interest, keywords"
          value={searchQueryJob}
          onChange={handleInputChangeJob}
          className="search-input"
        />

          <SelectedOption onClick={() => setIsOpenJob(!isOpenJob)}>
           
            <IconSvg.ArrowDown/>
          </SelectedOption>
          {isOpenJob && (
            <Options>
              <OptionItem onClick={() => handleOptionChangeJob('Job Title')}>Job Title</OptionItem>
              <OptionItem onClick={() => handleOptionChangeJob('Hobbies')}>Hobbies</OptionItem>
              <OptionItem onClick={() => handleOptionChangeJob('Interest')}>Interest</OptionItem>
              <OptionItem onClick={() => handleOptionChangeJob('Keywords')}>Keywords</OptionItem>
            </Options>
          )}
      </SearchInput>
      <SearchInput>
        <IconSvg.location className='search-icon'/>
        <InputField
          type="text"
          placeholder="Where Suburb, town or city"
          value={searchQueryLocation}
          onChange={handleInputChangeLocation}
          className="search-input"
        />

          <SelectedOption onClick={() => setIsOpenLocation(!isOpenLocation)}>
          
            <IconSvg.ArrowDown/>
          </SelectedOption>
          {isOpenLocation && (
            <Options>
              <OptionItem onClick={() => handleOptionChangeLocation('Job Title')}>Job Title</OptionItem>
              <OptionItem onClick={() => handleOptionChangeLocation('Hobbies')}>Hobbies</OptionItem>
              <OptionItem onClick={() => handleOptionChangeLocation('Interest')}>Interest</OptionItem>
              <OptionItem onClick={() => handleOptionChangeLocation('Keywords')}>Keywords</OptionItem>
            </Options>
          )}
      </SearchInput>
      <SearchBtnContainer>
        <Button url={''} >Search</Button>
      </SearchBtnContainer>
      
    </SearchJobWrapper>
  );
};

export default JobSearch;
