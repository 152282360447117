import styled from "styled-components";



export const CardContainer = styled.div`
  --transition-speed: 200ms;
  border-radius: 12px;
  overflow: hidden;
  padding: 18px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index:0;
  transition: background-color var(--transition-speed) ease-in-out, color var(--transition-speed) ease-in-out;
  
  .heading{
    font-size:18px;
    color:#000;
  }

  &.active, &:hover{
    background-color: #5C5AD6;
    color: #fff;
    * {
      color: #fff;
    }

    .heading{
      color: #fff;
      
    }
    }
  }

  .heading{
    transition: color var(--transition-speed) ease-in-out;
    font
  }

  .description {
    &:not(:last-child){
      margin-bottom: 24px;  
    }
  }
`

