import styled from "styled-components";

export const CustomerDetail = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;

    &:before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        top: 50%;
        left: calc((30px / 2)  - (5px / 2));
        transform: translateY(-50%);
        background-color: #111111;
        opacity: 0.5;
    }

    p {
        color: #111111;
        opacity: 0.5;
    }
`;

export const ReviewCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    padding: 24px;
    flex: 1;

    > {
        p {
            color: #023020;
        }

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
`;

export const SliderNextButton = styled.button`
    &:hover {
        svg {
            animation: LeftToRight 1s linear infinite ;
        }
    }  
`;

export const SliderPrevButton = styled.button`
    &:hover {
        svg {
            animation: RightToLeft 1s linear infinite;
        }
    }
`;

export const SliderAction = styled.div`
    display: flex;
    flex-direction: row;
    allign-items: center;

    @keyframes LeftToRight {
        0% {transform: translateX(0px)}
        25% {transform: translateX(-4px)}
        50% {transform: translateX(0px)}
        75% {transform: translateX(4px)}
        100% {transform: translateX(0px)}
    }

    @keyframes RightToLeft {
        0% {transform: translateX(0px)}
        25% {transform: translateX(4px)}
        50% {transform: translateX(0px)}
        75% {transform: translateX(-4px)}
        100% {transform: translateX(0px)}
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #484EA1;
        border-radius: 100%;
        width: 68px;
        height: 68px;
        background-color: transparent;
        cursor: pointer;
        
        &:first-child {
            margin-right : 10px;
        }

        svg {
            width: 30px;
            height: 30px;
        }

        &:hover {
            background-color: #484EA1;

            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }
`;

export const SectionHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-contemt: space-between;
    margin-bottom: 60px;
    width: 100%;

    h2 {
        width: 50%;
        margin-right: auto;
    }
`;

export const SliderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    > {
        &:not(:last-child) {
            margin-right: 70px;
        }
    }
`;

export const CustomerReviewWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 0px;
    background-color: #F3F3F8;

    @media only screen and (max-width: 1024px) {
        ${SectionHeader} {
            h2 {
                flex: 1;
            }
        }

        ${SliderContainer} {
            > {
                &:not(:last-child) {
                    margin-right: 30px;
                }
            }
        }
    }

    @media only screen and (max-width: 548px) {
        ${SectionHeader} {
            flex-direction: column;
            align-items: flex-start;
            h2 {
                flex: 1;
                width: 100%;
                margin-bottom: 20px;
            }
        }

        ${SliderContainer} {
            flex-direction: column;
            > {
                &:not(:last-child) {
                    margin-right: 0px;
                    margin-bottom: 30px;
                }
            }
        }
    }
`;