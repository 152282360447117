import styled from "styled-components";
import { colors } from "../../../../common/constants/color";

export const TextContent = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-bottom: 2%;
    .heading{
      color:  ${colors.primaryColor};
    }
    @media only screen and (max-width: 1200px) {
        width: 70%;
    }
    @media only screen and (max-width: 991px) {
        width: 100%;
        text-align:center;
        align-items:center;
    }
`;
export const ImgContent = styled.div`
    width: 50%;
    text-align:right;
    @media only screen and (max-width: 1200px) {
        width: 30%;
    }
    @media only screen and (max-width: 991px) {
        width: 100%;
        text-align:center;
    }
    .register-img{
        max-height:350px;
    }
`
export const AuthBannerContainer = styled.div`
    display:flex;
    flex-wrap:wrap;
    padding-top: calc(95px + 5%);
    align-items:center;
    
`