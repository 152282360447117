import styled from 'styled-components';

export const JobCardContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding:30px;
  margin-bottom: 20px;
`;

export const PostedTime = styled.div`
  font-size: 12px;
  color: #666;
`;
export const LocationContainer = styled.div`
  color:#646168;
  margin-bottom:20px;
  margin-top:10px;
  display:flex;
  align-items:center;
  .icon{
    margin-right:6px;
  }
`
export const ApplyContainer = styled.div`
  display:flex;
  align-items:center;
  margin-bottom:20px;
  .save-btn{
    background-color:#E4E2E0;
    margin-left:16px;
    width:40px;
    height: 40px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:6px;
  }
  `

export const JobDetails = styled.div`

  border-top:solid 1px #D4D4D4;
  border-bottom:solid 1px #D4D4D4;
  padding-top:26px;
  padding-bottom:20px;
  p{
    margin-bottom:10px;
  }
  .job-head{
    font-weight:400;
    margin-bottom:8px;
  }
  .desc{
    margin-bottom:12px;
  }
  .title{
    font-weight:600;
    color:#023020;
    margin-bottom:6px;
  }
`
export const JobDetailDescription =styled.div`
    padding: 30px 0;
    max-height:500px;
    overflow-y:scroll;
    .heading{
      margin-bottom:20px;
    }
`