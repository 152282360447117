import React, { FC } from "react";
import { CreateProfileSection, CreateProfileWrapper } from "./style";
import CreateProfileForm from "../components/formContainer";
import UserDetail from "../components/userDetail";
import AddressDetail from "../components/addressDetail";
import EducationDetail from "../components/educationDetail";

interface CreateProfileProps {

}

const CreateProfile: FC<CreateProfileProps> = () => {

    const profileHandle = () => {
        
    }
    return (
        <CreateProfileSection>
            <CreateProfileWrapper>
                <CreateProfileForm onClick={profileHandle} progressStatus={10}>
                    {/* <UserDetail/> */}
                    {/* <AddressDetail/> */}
                    <EducationDetail/>
                </CreateProfileForm>
            </CreateProfileWrapper>
        </CreateProfileSection>
    )
}

export default CreateProfile;