import styled from "styled-components";
import { colors } from "../../../common/constants/color";
import { Heading1, Wrapper } from "../../../common/constants/core.style";

export const CareerBannerContainer = styled.div`
    background-color : ${colors.lightBG};

    .heading{
        margin-bottom:24px;
    }
`

export const MainSectionContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap:wrap;
`;



export const MainSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    > {
        img {
            width: 100%;
        }
    }
    
    @media only screen and (max-width: 1199px) {

        ${Wrapper} {
            width: 100%;
        }

    }

    @media only screen and (max-width: 767px) {
        

        
    }
`;
export const JobCardsContainer = styled.div`
    display:flex;
    padding: 60px 0;
    @media only screen and (max-width: 991px) {
        flex-wrap:wrap;
    }

    .left-section{
        width:35%;
        @media only screen and (max-width: 991px) {
           width:100%;
        }
        .label{
            margin-bottom:10px;
            margin-left:10px;
        }

    }
    .right-section{
        width:calc(65% - 15px);
        margin-left:30px;
        @media only screen and (max-width: 991px) {
            width:100%;
            margin-left:0px;
         }
    }
`