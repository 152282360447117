import React, { Children, FC } from "react";
import { FormAction, FormContainer, FormContent, FormHeader, ProgressBar, StatusBar } from "./style";
import { Paragraph } from "../../../../common/constants/core.style";
import { IconSvg } from "../../../../common/constants/images";
import { Button } from "../../../../common/components";

interface CreateProfileFormProp {
    children: any;
    onClick: () => void;
    progressStatus: number;
}

const CreateProfileForm: FC<CreateProfileFormProp> = (props) => {
    const {
        children,
        onClick,
        progressStatus = 0,
    } = props
    return (
        <FormContainer>
            <FormHeader>
                <IconSvg.ArrowLeft />
                <Paragraph>Save and Exit</Paragraph>
                <ProgressBar>
                    <StatusBar style={{ '--progress-status': `${progressStatus}%` } as React.CSSProperties} />
                </ProgressBar>
            </FormHeader>
            <FormContent>
                {children}
            </FormContent>
            <FormAction>
                <Button
                    label="Skip"
                    onClick={onClick}
                    variant='plain'
                />
                <Button
                    label="Continue"
                    onClick={onClick}
                    variant='primary'
                />
            </FormAction>
        </FormContainer>
    )
}

export default CreateProfileForm;