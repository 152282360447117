import React, { FC } from "react";
import { PhotoCollageContainer, PhotoCollageWrapper, TextContainer } from "./style";
import { Images } from "../../../../common/constants/images";
import { Head2, Paragraph } from "../../../../common/constants/core.style";

interface PhotoCollageProps {

}

const PhotoCollage: FC<PhotoCollageProps> = () => {
    return (
        <PhotoCollageWrapper>
            <PhotoCollageContainer>
                <img src={Images.Collage_1} alt="" />
                <img src={Images.Collage_2} alt="" />
                <img src={Images.Collage_3} alt="" />
                <img src={Images.Collage_4} alt="" />
                <img src={Images.Collage_5} alt="" />
            </PhotoCollageContainer>
            <TextContainer>
                <Head2>Together we are <strong>strong</strong></Head2>
                <Paragraph className="text-lg">As a locally owned and operated business in New Zealand, we offer individualized care solutions that genuinely satisfy the requirements of our customers. We are here to make sure you receive the best care possible so you can live life on your terms without worrying. We do this by matching you with caregivers who share our values and by putting a strong emphasis on providing high-quality, flexible, and consistent care services.</Paragraph>
            </TextContainer>
        </PhotoCollageWrapper>
    )
}

export default PhotoCollage;