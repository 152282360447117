import styled from "styled-components";
import { colors } from "../../constants/color";

export const SelectDropdownItem = styled.li`
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    word-break: break-all;

    &:hover {
        background-color: ${colors.primaryColor};
        color: #fff;
    }

    &.wc-active {
        font-weight: bold;
        color: ${colors.primaryColor};

        &:hover {
            background-color: ${colors.primaryColor};
            color: #fff;
        }
    }
`

export const SelectDropdown = styled.ul`
    position: absolute;
    top: calc(100% + 2px);
    width: 100%;
    left: 0px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px #000;
    list-style: none;
`

export const SelectLabel = styled.div`
    span {
        color: #FF0054;
    }
`

export const SelectSelectedLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const SelectContainer = styled.div`
    display: flex;
    position: relative;

    ${SelectSelectedLabel} {
        width: 100%;
        background-color: #fff;
        border: 1px solid #7070704d;
        height: 42px;
        border-radius: 6px;
        padding: 18px 12px;
        padding-right: 32px;
        overflow: hidden;
        color: #707070;
        cursor: pointer;
        white-space: nowrap;
        position: relative;

        span {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        svg {
            position: absolute;
            width: 15px;
            height: 15px;
            right: 10px;
            top: 50%;
            transform: translateY(-50%) rotate(0deg);
            transition: all 0.3s ease-in-out;
        }

        &.wc-expanded {
            svg {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        &.wc-select-disabled {
            background-color: #EAECEF;
            border-color: #EAECEF;
            cursor: not-allowed;
        }
    }

    &.wc-select-horizontal {
        flex-direction: row;

        ${SelectLabel} {
            margin-right: 8px;
        }
    }

    &.wc-select-vertical {
        flex-direction: column;

        ${SelectLabel} {
            margin-bottom: 8px;
        }
    }

    &.wc-select-small {
        ${SelectSelectedLabel} {
            height: 42px;
        }

        ${SelectSelectedLabel} {
            font-size: 14px;
        }

        ${SelectDropdownItem} {
            font-size: 14px;
        }

        ${SelectLabel} {
            font-weight: 600;
            font-size: 14px;
            color: #023020;
        }
    }

    &.wc-select-large {
        ${SelectSelectedLabel} {
            height: 60px;
            font-size: 20px
        }

        ${SelectSelectedLabel} {
            font-size: 20px;
        }

        ${SelectDropdownItem} {
            font-size: 20px;
        }

        ${SelectLabel} {
            font-weight: 400;
            font-size: 20px;
            color: #2D3035;
        }
    }

`