import styled from 'styled-components';
import { colors } from '../../../../common/constants/color';

export const SignCardContainer = styled.div`
    background-color:${colors.lightBG};
    padding:40px 110px; 
    width:100%;
    display:flex;
    align-items:center;
    margin:100px 0px;
    flex-wrap:wrap;
    border-radius:6px;
    @media only screen and (max-width: 1200px) {
        justify-content:center;
    }
    .text{
        margin-left:15px;
        @media only screen and (max-width: 1200px) {
            margin-top:10px;
        }
    }
    .link-with-underline{
        text-decoration:underline;
        font-weight:600;
    }
`