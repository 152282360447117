import React, { FC } from "react";
import { CardWrapper, TextContainer } from "./style";
import { Head2, Paragraph, Section, Wrapper } from "../../../../common/constants/core.style";

interface cardListingProp {
  heading: string;
  description: string;
  children: React.ReactNode;
}

const CardListing: FC<cardListingProp> = ({ heading, description, children }) => {
  return (
    <Section>
      <Wrapper>
        <TextContainer>
          <Head2>{heading}</Head2>
          <Paragraph className="text-md">{description}</Paragraph>
        </TextContainer>

        <CardWrapper>
          {children}
        </CardWrapper>
      </Wrapper>
    </Section>
  )
}

export default CardListing;
