import styled from 'styled-components';

export const BacktoTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    p {
        color: #fff;
        opacity: 0.6;
        font-size: 20px;
        font-weight: 600;
        white-space: nowrap;
    }

    svg {
        margin-left: 12px;
    }
`;

export const FooterDivider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #fff;
    opacity: 0.3;
`;

export const FooterMenuLink = styled.div`
    display: flex;
    flex-direction: row;

    a {
        color: #fff;
        opacity: 0.6;
        font-size: 20px;
        font-weight: 600;

        &:not(:last-child) {
            margin-right: 60px;
        }
    }

    p {
        margin-right: 60px;
        color: #fff;
        opacity: 0.6;
        font-size: 20px;
        font-weight: 600;
    }

    @media only screen and (max-width: 991px) {
        flex-wrap: wrap;
        margin-top: 30px;
        gap: 30px;
        align-items: center;
        justify-content: center;
        width: 100%;

        a {
            margin-right: 0px;
            &:not(:last-child) {
                margin-right: 0px;
            }
        }

        p {
            margin-right: 0px;
            width: 100%;
            text-align: center;
        }
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 66px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 66px;
        
        img {
            height: 100%;
            width: auto;
        }
    }
`;

export const TopFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0px;

    @media only screen and (max-width: 991px) {
        flex-direction: column;
        align-items: center;

        ${FooterMenuLink} {
            flex-wrap: wrap;
            align-items: center;
            row-gap: 30px;
            * {
                flex: 1;
                text-align: center;
            }
        }
    }
`;

export const BottomFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 60px;
    padding-bottom: 44px;

    @media only screen and (max-width: 991px) {
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
        padding-top: 30px;

    }
`;

export const FooterContent = styled.div`
    max-width: 1280px;
    width: 100%;

    @media only screen and (max-width: 1199px) {
        padding-right: 15px;
        padding-left: 15px;
    }
`;

export const FooterWrapper = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #0A0A1F;
`;