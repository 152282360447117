import React, { FC } from "react";
import { Button, MultiText } from "../../../common/components";
import { AboutSection, LeftContent, CustomerProfileList, RightContent, Divider } from "./style";
import HeroSection from "../components/mainSection";
import { Head2, Paragraph, Wrapper } from "../../../common/constants/core.style";
import { IconSvg, Images } from "../../../common/constants/images";
import PhotoCollage from "../components/PhotoCollage";

const AboutUs: FC = () => {
    return (
        <>
            <HeroSection />
            <AboutSection>
                <IconSvg.CircleBgShapeSmall />
                <IconSvg.CircleBgShape />
                <Wrapper>
                    <Divider />
                    <LeftContent>
                        <Head2>Together We Are <strong>Strong</strong></Head2>
                        <CustomerProfileList>
                            <span><img src={Images.image_1} alt="" /></span>
                            <span><img src={Images.image_1} alt="" /></span>
                            <span><img src={Images.image_1} alt="" /></span>
                            <span><img src={Images.image_1} alt="" /></span>
                        </CustomerProfileList>
                        <Button
                            label="Get Started"
                            url="#"
                        />
                    </LeftContent>
                    <RightContent>
                        <Paragraph className="text-lg">Welcare was established in the year 2024 by Christopher Benison in the aim of providing the best possible services for the elderly in the Manawatu and Palmerston regions. Run by a group of nurses willing to do meaningful and compassionate care for the elderly, we would proudly say that you will get the care you wanted.</Paragraph>
                        <Paragraph className="text-md">We provide special assistance in dementia care, palliative care, and respite care, and understand the unique challenges and needs of our elderly. Our focus is not only on providing care but also on fostering the mental health and overall well-being of our clients. Welcare ensures that you can continue living independently with confidence.</Paragraph>
                    </RightContent>
                </Wrapper>
            </AboutSection>
            <PhotoCollage />
            <MultiText />
        </>
    )
}

export default AboutUs;