// JobCard.tsx
import React, { useState } from "react";
import { Head4 } from "../../../common/constants/core.style";
import { IconSvg } from "../../../common/constants/images";
import {
  JobCardContainer,
  Header,
  StatusBadge,
  MenuIcon,
  DropdownMenu,
  List,
  ListItem,
  PostedTime,
  TagList,
  TagItem,
  LocationContainer,
  MatchingContainer,
} from "./style";

const JobCard: React.FC<{ isNew: boolean }> = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleRemoveClick = () => {
    console.log("Remove clicked");
  };

  return (
    <JobCardContainer>
      <Header>
        <StatusBadge>New</StatusBadge>
        <MenuIcon onClick={handleMenuClick}>
          <IconSvg.ThreeDots />
        </MenuIcon>
        {showMenu && (
          <DropdownMenu>
            <div onClick={handleRemoveClick}>Remove</div>
          </DropdownMenu>
        )}
      </Header>
      <Head4>Registered Nurse</Head4>
      <LocationContainer><IconSvg.location className="icon" />Melbourne VIC, Australia</LocationContainer>
      <MatchingContainer><IconSvg.Mark className="icon" />90% of your profile is matching</MatchingContainer>
      
      <TagList>
        <TagItem>Music</TagItem>
        <TagItem>Child Care</TagItem>
        <TagItem>Football</TagItem>
        <TagItem>+3</TagItem>
      </TagList>
      <List>
        <ListItem>Monthly Salary Range : Not disclosed.</ListItem>
        <ListItem>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since...</ListItem>
      </List>
      <PostedTime>Just posted</PostedTime>
    </JobCardContainer>
  );
};

export default JobCard;
