import styled from 'styled-components';

export const JobCardContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  position:relative;
`;

export const StatusBadge = styled.span`
  color: #FF0054;
`;

export const MenuIcon = styled.div`
  cursor: pointer;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: -6px;
  right: 10px;
  background-color: #ff4572;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  z-index: 100;
  color: #ffff;
`;

export const Heading = styled.h2`
  margin-bottom: 10px;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom:20px;
`;

export const ListItem = styled.li`
  margin-bottom: 8px;
  position:relative;
  padding-left:18px;
  max-height:54px;
  overflow:hidden;
  &:before{
    content:'';
    width:6px;
    height:6px;
    background-color:#646168;
    position:absolute;
    border-radius:50%;
    left:0;
    top:6px;
  }
`;

export const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom:14px;
`;

export const TagItem = styled.div`
  background-color: #fff;
  color: #2058D1;
  border:solid 1px #2058D1;
  padding: 5px 10px;
  border-radius: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

export const PostedTime = styled.div`
  font-size: 12px;
  color: #666;
`;
export const LocationContainer = styled.div`
  color:#646168;
  margin-bottom:10px;
  margin-top:10px;
  display:flex;
  align-items:center;
  .icon{
    margin-right:6px;
  }
`
export const MatchingContainer = styled.div`
  background-color:#E4F7E6;
  padding:8px 10px;
  color:#1F662C;
  border-radius:4px;
  display:inline-block;
  margin-bottom:12px;
  .icon{
    margin-right:6px;
  }
`