import styled from "styled-components";
import { Head2 } from "../../constants/core.style";
import { CardContainer } from "../card/style";

export const TextContainer = styled.div`
  max-width: 800px;
  margin-inline: auto;
  margin-bottom: 64px;
  text-align: center;

  ${Head2}{
    max-width: 90%;
    margin-inline: auto;
    margin-bottom: 26px;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 30px;

  ${CardContainer}{
    min-width: calc(100% / var(--card-per-grid) - 40px);
    flex: 1;
  }

  @media screen and (max-width: 1024px) {
    gap: 20px;

    ${CardContainer}{
      min-width: calc(100% / 2 - 40px);
    }
  }

  @media screen and (max-width: 648px) {
    gap: 20px;
    
    ${CardContainer}{
      min-width: calc(100%);
    }
  }
`