import React, { FC } from "react";
import {
  AboutSection,
  LeftContent,
  CustomerProfileList,
  RightContent,
  Divider,

} from "./style";
import HeroSection from "../components/mainSection";
import {
  Head2,
  Head4,
  Paragraph,
  Wrapper,
} from "../../../common/constants/core.style";
import { IconSvg, Images } from "../../../common/constants/images";
import PhotoCollage from "../components/PhotoCollage";
import CardListing from "../components/cardListing";
import ServiceCard from "../../../common/components/serviceCard";

const HomeService: FC = () => {
  return (
    <>
      <HeroSection title={"Help at "} titleMain={"home Services"} description={"Welcare specializes in offering individualized, caring 'Help at Home' services to help senior citizens stay safe, independent, and happy in the comfort of their own homes. With a thorough awareness of the special requirements and difficulties that older people encounter, we are dedicated to providing dependable support and company to improve their quality of life."}  />
      <AboutSection>
        <IconSvg.CircleBgShapeSmall />
        <IconSvg.CircleBgShape />
        <Wrapper>
          <Divider />
          <LeftContent>
            <Head2>
              Our <strong>services</strong>
            </Head2>
            <CustomerProfileList>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
            </CustomerProfileList>
          </LeftContent>
          <RightContent>
            <Paragraph className="text-lg">Companionship</Paragraph>
            <Paragraph className="text-lg">Light housekeeping</Paragraph>
            <Paragraph className="text-lg">Private chefs</Paragraph>
            <Paragraph className="text-lg">
              Senior transportation services
            </Paragraph>
          </RightContent>
        </Wrapper>
      </AboutSection>
      <PhotoCollage />

      <CardListing
        heading="Companionship"
        description="Welcare offers companionship services for elderly people at home. Our top priority is making them happy, so we provide them with emotional support, socialization, transportation to and from medical appointments, or keeping good company while they read a book, watch a movie, or indulge in a hobby.
        "
      >
        <Head4>Services</Head4>
        <ServiceCard heading="Shopping" />
        <ServiceCard heading="Running errands" />
        <ServiceCard heading="Emotional support" />
        <ServiceCard heading="Walking" />
        <ServiceCard heading="Social gatherings" />
        <ServiceCard heading="Outdoor activities" />
        <ServiceCard heading="Escort to appointments" />
        <Head4>Benefits</Head4>
        <ServiceCard heading="Improves brain health" />
        <ServiceCard heading="Encourages socialisation" />
        <ServiceCard heading="Alleviates lonely feeling" />
        <ServiceCard heading="Reduces stress" />
        <ServiceCard heading="Encourages exercise & promotes physical health" />
        
      </CardListing>

      <CardListing
        heading="Light housekeeping"
        description="When day-by-day tasks get tougher as you grow old, Welcare can be your housekeeping service provider. With our services, you can rest assured that your home will be expertly cared for.
        "
      >
        <Head4>Services</Head4>
        <ServiceCard heading="Make beds" />
        <ServiceCard heading="Light gardening" />
        <ServiceCard heading="Take out the trash" />
        <ServiceCard heading="Clean cupboards" />
        <ServiceCard heading="Vacuum and dusting" />
        <Head4>Benefits</Head4>
        <ServiceCard heading="Gives Clean environment" />
        <ServiceCard heading="Enables Safety" />
        <ServiceCard heading="Promotes independence" />
        <ServiceCard heading="Reduces stress" />
        <ServiceCard heading="Provides Comfort" />
        
      </CardListing>

      <CardListing
        heading="Private chefs"
        description="A private chef can be of great assistance to senior citizens by making sure they have access to scrumptious, nourishing meals that satisfy their dietary requirements and preferences. As a result, seniors can continue to enjoy food, be independent, and maintain their health without having to worry about the stress or inconvenience of meal preparation.
        "
      >
        <Head4>Services</Head4>
        <ServiceCard heading="Personalised meal" />
        <ServiceCard heading="Balanced food" />
        <ServiceCard heading="Portion control" />
        <ServiceCard heading="Dietary support" />

        <Head4>Benefits</Head4>
        <ServiceCard heading="Flexible schedule" />
        <ServiceCard heading="Saves time and effort" />
        <ServiceCard heading="Dietary needs are taken care of" />
        <ServiceCard heading="Balanced meals" />
        <ServiceCard heading="Nutritious food" />
        <ServiceCard heading="Promotes healthy eating" />
        
      </CardListing>

      <CardListing
        heading="Senior transportation services"
        description="Senior transportation services meet unique mobility needs of the elderly who have trouble driving or using conventional public transportation. Through safe and practical transportation options, these services enable senior citizens to preserve their independence and participate in social, medical, and community activities. Our senior transportation services provide a lifeline for seniors, giving them dependable transportation and peace of mind as they navigate everyday life with door-to-door service and specialized vehicles.
        "
      >
        <Head4>Services</Head4>
        <ServiceCard heading="Specialized Vehicles" />
        <ServiceCard heading="Scheduled Rides" />
        <ServiceCard heading="Membership or Registration" />
        <ServiceCard heading="Affordability and Subsidies" />
        <ServiceCard heading="Trained Drivers" />
        <ServiceCard heading="Companionship and Assistance" />
        <ServiceCard heading="Integration with Public Transportations" />
        <Head4>Benefits</Head4>
        <ServiceCard heading="Convenience" />
        <ServiceCard heading="Safety" />
        <ServiceCard heading="Access to essential service" />
        <ServiceCard heading="Companionship" />
        <ServiceCard heading="Independence" />
        
      </CardListing>
    </>
  );
};

export default HomeService;
