import styled from "styled-components";

export const InputContainer = styled.div`
    display: flex;

    label {
        span {
            color: #FF0054;
        }
    }
    
    input {
        width: 100%;
        background-color: #fff;
        border: 1px solid #7070704d;
        height: 42px;
        border-radius: 6px;
        padding: 18px 12px;
        overflow: hidden;
        font-size: 14px;
        color: #707070;

        &:disabled {
            background-color: #EAECEF;
            border-color: #EAECEF;
        }
    }

    &.wc-input-horizontal {
        flex-direction: row;

        label {
            margin-right: 8px;
        }
    }

    &.wc-input-vertical {
        flex-direction: column;

        label {
            margin-bottom: 8px;
        }
    }

    &.wc-input-small {
        input {
            height: 42px;
        }
        label {
            font-weight: 600;
            font-size: 14px;
            color: #023020;
        }
    }

    &.wc-input-large {
        input {
            height: 60px;
            font-size: 20px
        }
        label {
            font-weight: 400;
            font-size: 20px;
            color: #2D3035;
        }
    }

`