import styled from "styled-components";
import { Wrapper } from "../../../../common/constants/core.style";
import { colors } from "../../../../common/constants/color";

export const MainSectionContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const LeftContent = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: calc(95px + 5%);
    position: relative;
    padding-bottom: 5%;

    h1 {
        font-weight: 400;
        padding-right: 1%;

        span {
            color: ${colors.primaryColor};
            font-weight: 600;
            position: relative;
            display: inline-block;
        }
    }
`;

export const RightContent = styled.div`
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding-bottom: 5%;


    img {
        position: relative;
        bottom: 0px;
        right: 0px;
    }
`;

export const MainSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    > {
        img {
            width: 100%;
        }
    }
    
    @media only screen and (max-width: 1199px) {

        ${Wrapper} {
            width: 100%;
        }

        ${LeftContent} {
            width: 55%;
        }

        ${RightContent} {
            width: 45%;

            img {
                width: 120%;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        ${MainSectionContent} {
            flex-direction: column;
        }

        ${LeftContent} {
            width: 100%;
            z-index: 0;

            h1 {
                padding: 0px;
            }
        }

        ${RightContent} {
            width: 100%;
            padding-top: 5%;
            padding-bottom: 10%;

            img {
                width: 100%;
            }
        }
    }
`;
