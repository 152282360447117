import React, { FC, useState } from "react";
import { ButtonContainer } from "./style";
import { Link as RouterLink } from "react-router-dom";

interface ButtonProp {
    label?: string;
    url?: string;
    variant?: 'primary' | 'secondary' | 'outline' | 'light' | 'dark' | 'plain';
    children?: any;
    fullwidth?: boolean;
    onClick?: () => void;
    type?: 'button' | 'link';
    loading?: boolean;
}

const Button: FC<ButtonProp> = (props) => {
    const {
        label,
        url = '#',
        variant = 'primary',
        children,
        fullwidth,
        onClick,
        type = 'link',
        loading = false
    } = props;

    const handleClick = () => {
        onClick && onClick();
    };

    return (
        <ButtonContainer className={fullwidth ? `wc-btn-${variant} w-100` : `wc-btn-${variant}`} onClick={handleClick}>
            {loading ? (
                <div className="button--loading">
                    <div className="button__loader"></div>
                </div>
            ) : (
                <>
                    {type === 'link' ? (
                        <RouterLink to={url}>
                            {children ? children : label}
                        </RouterLink>
                    ) : (
                        <button>
                            {children ? children : label}
                        </button>
                    )}
                </>
            )}
        </ButtonContainer>
    )
}

export default Button;
