import React, { FC, ChangeEvent } from "react";
import { CheckBoxIcon, CheckboxContainer } from "./style";
import { IconSvg } from "../../constants/images";

interface CheckBoxProps {
    label: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (checked: boolean, event?: ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox: FC<CheckBoxProps> = (props) => {
    const {
        label,
        checked = false,
        disabled = false,
        onChange
    } = props
    
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.checked, event);
        }
    };

    return (
        <CheckboxContainer>
            <label>
                <input
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                />
                <CheckBoxIcon>
                    <IconSvg.TickIcon/>
                </CheckBoxIcon>
                {label}
            </label>
        </CheckboxContainer>
    );
};

export default CheckBox;
