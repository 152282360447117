import React, { FC } from "react";
import CardListing from "../../../common/components/cardListing";
import HeroSection from "../components/mainSection";
import CustomerReviewSection from "../components/customerReviewSection";
import { TextAndMedia, MultiText } from "../../../common/components";
import { Images } from "../../../common/constants/images";
import { ListContainer, ListItem } from "./style";
import { Head2, Head4, Paragraph } from "../../../common/constants/core.style";

interface HomeProps {

}

const Home: FC<HomeProps> = () => {
    const cardData = [
        {
          heading: 'Search for a job',
          description: 'If you are up for a job that is about helping people, then register with us and easily find jobs that suit you the best',
          // url: '#'
        },
        {
          heading: 'Search for a care staff',
          description: 'Do you need a care staff to assist you? Join us, we can find a personalized care staff according to your needs.',
          // url: '#'
        },
        {
          heading: 'Search for a nursing service',
          description: 'Need help with healthcare, mental health, or disability? We can assist you to find one healthcare staff easily.',
          // url: '#'
        },
        {
          heading: 'Book a respite home',
          description: 'Book our respite home if you need to stay at a comfortable place with healthcare facilities at your service.',
          // url: '#'
        },
        {
          heading: 'Contact us',
          description: 'Reach out to us to discuss more details about our services.',
          // url: '#'
        }
      ]
    return (
        <>
            <HeroSection />
            <CardListing
                heading="Welcare Elderly Support- Ngā ratonga tautoko mō te hunga kaumātua"
                description="Our platform caters to both job seekers & job providers to fulfill the requirements of both parties. Join us now to experience the convenience of finding the perfect match for your needs with just a few clicks."
                cardData={cardData}
            />
            <TextAndMedia
                image={Images.image_1}
                heading="Our mission"
                description="Our mission is to improve our client's quality of life by offering individualized, compassionate care services. We are dedicated to maximizing each person's independence, health, and well-being so they can live comfortably in their homes with confidence. In addition, we have a respite home that serves temporary care relief for mental health wellbeing and disability support. We seek to significantly improve the lives of people we serve by maintaining a steady commitment to the highest standards of care, honesty, and empathy. We also work to establish dependable, respectful, and compassionate relationships. Our mission is to be our clients' and their families' reliable care partner, providing genuine support and companionship that makes a positive difference in their lives."
                buttonText="Learn More"
                buttonUrl="#"
                direction='ltr'
            />
            <TextAndMedia
                image={Images.image_2}
                heading="Our vision"
                buttonText="Learn More"
                buttonUrl="#"
                direction='rtl'
            >
                <ListContainer>
                    <Paragraph>Our vision is to be the leading provider of compassionate, all-inclusive health support, enabling people to reach their full potential in terms of health and wellness.</Paragraph>
                    <ListItem>
                        <span>1</span>
                        <Paragraph className="text-md">Authentic Care</Paragraph>
                    </ListItem>
                    <ListItem>
                        <span>2</span>
                        <Paragraph className="text-md">Maximized Health and Wellbeing support</Paragraph>
                    </ListItem>
                    <ListItem>
                        <span>3</span>
                        <Paragraph className="text-md">Run by nurses</Paragraph>
                    </ListItem>
                    <ListItem>
                        <span>4</span>
                        <Paragraph className="text-md">Extraordinary Service</Paragraph>
                    </ListItem>
                    <ListItem>
                        <span>5</span>
                        <Paragraph className="text-md">Locally Operated</Paragraph>
                    </ListItem>
                    <ListItem>
                        <span>6</span>
                        <Paragraph className="text-md">Compatible caregivers</Paragraph>
                    </ListItem>
                    <ListItem>
                        <span>7</span>
                        <Paragraph className="text-md">Quality & Flexibility</Paragraph>
                    </ListItem>
                </ListContainer>
            </TextAndMedia>
            <CustomerReviewSection />
            <MultiText/>
        </>
    )
}

export default Home;