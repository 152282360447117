import React, { FC, useState, ChangeEvent, FormEvent } from "react";
import { Button, CustomInput, ToastMessage } from "../../../common/components";
import { Head3, Head4, Paragraph, Wrapper } from "../../../common/constants/core.style";
import { IconSvg, Images } from "../../../common/constants/images";
import Banner from "../components/banner";

import {
  LeftColumn,
  RightColumn,
  Input,
  AuthBanner,
  RegisterContainer,
  QRcontainer,
  ImgContainer,
  Contact,
  IconWithLink,
  ContactFormCard,
  FormItem,
  FormItemHalf,
  FormAction,
} from "./style";
import ContactUsService from "../../../services/contact-us.service";

interface FormData {
  name: string;
  email: string;
  mobile: string;
  location: string;
}

const RegisterPage: FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // State variables to track form fields and errors
  const [firstName, setFirstName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // State variables to track form field errors
  const [firstNameError, setFirstNameError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const validateString = (value: any) => {
    return value.trim() === "" ? "This field is required" : "";
  };


  const validateEmail = (value: any) => {
    if (value.trim() === "") {
      return "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(value)) {
      return "Invalid email address";
    }
    return "";
  };


  const handleSubmit = () => {
    setSubmitting(true);

    // Validate each field
    const firstNameError = validateString(firstName);
    const lastNameError = validateString(location);
    const emailError = validateEmail(email);
    const phoneNumberError = validateString(phoneNumber);


    // Set errors in state
    setFirstNameError(firstNameError);
    setLocationError(lastNameError);
    setEmailError(emailError);
    setPhoneNumberError(phoneNumberError);

    if (
      !firstNameError &&
      !lastNameError &&
      !emailError &&
      !phoneNumberError
      ) {

      // Prepare the data object to be sent to the ContactUsService
      const formData = {
        name: firstName,
        location: location,
        email: email,
        phone: phoneNumber
      };

      ContactUsService.register(formData)
        .then((data) => {
          if (data) {
            toast("Contact Form Submitted Successfully");
            resetForm();
            setSubmitting(false);
            // toast("Review submitted successfully");
          } else {
            // Handle error here if needed
          }
        })
        .catch((error) => {
          console.log(error);
          //   toast("Something went wrong, Please try again");
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
    else {
      setSubmitting(false);
    }
  };

  const resetForm = () => {
    // Reset form fields and errors
    setFirstName('');
    setLocation('');
    setEmail('');
    setPhoneNumber('');

    setFirstNameError('');
    setLocationError('');
    setEmailError('');
    setPhoneNumberError('');
  };

  const toast = (msg: any) => {
    setToastMessage(msg);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  }


  return (
    <>
      <AuthBanner>
        <Wrapper>
          <Banner />
        </Wrapper>
      </AuthBanner>
      <Wrapper>
        <RegisterContainer>
          <LeftColumn>
            <Head4 >Are you looking for a related job opening?</Head4>
            <Head4 className="primary-head">Register your needs, find your leads</Head4>
            {/* <Contact>
              <IconWithLink>
                <IconSvg.PhoneIcon className="phone-icon" />
                <a href="tel:+1 234 234 56 78">+64 225250726</a>
              </IconWithLink>
              <IconWithLink>
                <IconSvg.location />
                <a>Palmerston North</a>
              </IconWithLink>
            </Contact> */}
          </LeftColumn>
          <RightColumn>
            <ContactFormCard>
              <FormItem>
              <CustomInput
                    label="Name"
                    id="name"
                    type="text"
                    placeholder='First Name'
                    size='large'
                    onChange={(e: any) => setFirstName(e)}
                    inputValue={firstName}
                    error={firstNameError}
                />
              </FormItem>
              <FormItem>
                <CustomInput
                    label="Email"
                    id="email"
                    type="email"
                    size='large'
                    placeholder="Email"
                    error={emailError}
                    inputValue={email}
                    onChange={(e: any) => setEmail(e)}
                />
              </FormItem>
              <FormItem>
                <CustomInput
                      id="location"
                      label="Location"
                      type="text"
                      placeholder="Location"
                      size='large'
                      error={locationError}
                      inputValue={location}
                      onChange={(e: any) => setLocation(e)}
                  />
              </FormItem>
              <FormItem>
                <CustomInput
                    label="Phone Number"
                    id="tel"
                    type="tel"
                    size='large'
                    placeholder="Phone Number"
                    error={phoneNumberError}
                    inputValue={phoneNumber}
                    onChange={(e: any) => setPhoneNumber(e)}
                />
              </FormItem>
              <FormAction>
                <Button
                  label="Register Now"
                  onClick={() => handleSubmit()}
                  variant='primary'
                  type="button"
                  fullwidth
                />
              </FormAction>
              <ToastMessage
                message={toastMessage}
                show={showToast}
              />
            </ContactFormCard>
          </RightColumn>
        </RegisterContainer>
      </Wrapper></>
  );
};

export default RegisterPage;
