import apiService from './api.service';

// User service for managing user-related APIs
const ContactUsService = (() => {
  
  return {
    // Login API call
    contactUs: async (data: any) => {
      try {
        const response = await (await apiService).post('/contact-us', data);
        return response;
      } catch (error) {
        throw error;
      }
    },

    register: async (data: any) => {
      try {
        const response = await (await apiService).post('/register', data);
        return response;
      } catch (error) {
        throw error;
      }
    },

  };
})();

export default ContactUsService;
