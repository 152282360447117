import React, { FC, FormEvent, useState } from "react";
import { ContactFormCard, FormAction, FormItem, FormItemHalf } from "./style";
import { Button, CustomInput, CustomTextArea, ToastMessage } from "../../../../common/components";
import ContactUsService from "../../../../services/contact-us.service";

interface ContactFormProps {

}

const ContactForm: FC<ContactFormProps> = () => {
    const [submitting, setSubmitting] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    
    // State variables to track form fields and errors
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    // State variables to track form field errors
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [messageError, setMessageError] = useState("");

    const validateString = (value: any) => {
        return value.trim() === "" ? "This field is required" : "";
    };


    const validateEmail = (value: any) => {
        if (value.trim() === "") {
            return "Email is required";
        } else if (!/^\S+@\S+\.\S+$/.test(value)) {
            return "Invalid email address";
        }
        return "";
    };


    const handleSubmit = () => {
        setSubmitting(true);

        // Validate each field
        const firstNameError = validateString(firstName);
        const lastNameError = validateString(lastName);
        const emailError = validateEmail(email);
        const phoneNumberError = validateString(phoneNumber);
        const messageError = validateString(message);


        // Set errors in state
        setFirstNameError(firstNameError);
        setLastNameError(lastNameError);
        setEmailError(emailError);
        setPhoneNumberError(phoneNumberError);
        setMessageError(messageError);

        if (
            !firstNameError &&
            !lastNameError &&
            !emailError &&
            !phoneNumberError &&
            !messageError
        ) {

            // Prepare the data object to be sent to the ContactUsService
            const formData = {
                name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
                message: message
            };

            ContactUsService.contactUs(formData)
                .then((data) => {
                    if (data) {
                        toast("Contact Form Submitted Successfully");
                        resetForm();
                        setSubmitting(false);
                        // toast("Review submitted successfully");
                    } else {
                        // Handle error here if needed
                    }
                })
                .catch((error) => {
                    console.log(error);
                    //   toast("Something went wrong, Please try again");
                    setSubmitting(false);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        }
        else {
            setSubmitting(false);
        }
    };

    const resetForm = () => {
        // Reset form fields and errors
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setMessage('');

        setFirstNameError('');
        setLastNameError('');
        setEmailError('');
        setPhoneNumberError('');
        setMessageError('');
    };

    const toast = (msg: any) => {
        setToastMessage(msg);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    }

    return (
        <ContactFormCard>
            <FormItemHalf>
                <CustomInput
                    label="Name"
                    id="name"
                    type="text"
                    placeholder='First Name'
                    size='large'
                    onChange={(e: any) => setFirstName(e)}
                    inputValue={firstName}
                    error={firstNameError}
                />
                <CustomInput
                    id="name"
                    type="text"
                    placeholder="Last Name"
                    size='large'
                    error={lastNameError}
                    inputValue={lastName}
                    onChange={(e: any) => setLastName(e)}
                />
            </FormItemHalf>
            <FormItem>
                <CustomInput
                    label="Email"
                    id="email"
                    type="email"
                    size='large'
                    placeholder="Email"
                    error={emailError}
                    inputValue={email}
                    onChange={(e: any) => setEmail(e)}
                />
            </FormItem>
            <FormItem>
                <CustomInput
                    label="Phone Number"
                    id="tel"
                    type="tel"
                    size='large'
                    placeholder="Phone Number"
                    error={phoneNumberError}
                    inputValue={phoneNumber}
                    onChange={(e: any) => setPhoneNumber(e)}
                />
            </FormItem>
            <FormItem>
                <CustomTextArea
                    label="Message"
                    placeholder="Message"
                    size='large'
                    id="message"
                    error={messageError}
                    inputValue={message}
                    onChange={(e: any) => setMessage(e)}
                />
            </FormItem>
            <FormAction>
                <Button
                    label="Submit"
                    loading={submitting}
                    onClick={() => handleSubmit()}
                    variant='primary'
                    type='button'
                    fullwidth
                />
            </FormAction>
            <ToastMessage
                message={toastMessage}
                show={showToast}
            />
        </ContactFormCard>
    );
}

export default ContactForm;