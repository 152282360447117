import { FC } from "react";
import { Paragraph, Wrapper } from "../../../common/constants/core.style";
import CareerBanner from "../components/careerBanner";
import {
  CareerBannerContainer,
  MainSection,
  MainSectionContent,
  JobCardsContainer,
} from "./style";
import BlogCard from "../jobCard";
import JobDetailCard from "../JobDetailCard";
import JobCard from "../jobCard";

const JobBlog: FC = () => {
  return (
    <>
      <CareerBannerContainer>
        <MainSection>
          <Wrapper>
            <MainSectionContent>
              <CareerBanner />
            </MainSectionContent>
          </Wrapper>
        </MainSection>
      </CareerBannerContainer>
      <MainSection>
          <Wrapper>
            <JobCardsContainer>
              <div className="left-section">
                <Paragraph className="label">Jobs based on your activity</Paragraph>
                <JobCard isNew={false} />
                <JobCard isNew={false} />
                <JobCard isNew={false} />
              </div>
              <div className="right-section">
              <JobDetailCard/>
              </div>
            </JobCardsContainer>
          </Wrapper>
        </MainSection>
      
    </>
  );
};

export default JobBlog;
