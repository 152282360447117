import React, { FC } from "react";
import {
  AboutSection,
  LeftContent,
  CustomerProfileList,
  RightContent,
  Divider,

} from "./style";
import HeroSection from "../components/mainSection";
import {
  Head2,
  Head4,
  Paragraph,
  Wrapper,
} from "../../../common/constants/core.style";
import { IconSvg, Images } from "../../../common/constants/images";
import PhotoCollage from "../components/PhotoCollage";
import CardListing from "../components/cardListing";
import ServiceCard from "../../../common/components/serviceCard";

const NursingService: FC = () => {
  return (
    <>
      <HeroSection title={"Palliative"} description={"Palliative care focuses on offering improving quality of life to the best, managing symptoms, and help people remain comfortable during their remaining time. We provide at home palliative care because home is where the elders feel safe and secure. We offer two kinds of support according to your need"} titleMain={"Care"} />
      
      <AboutSection>
        <IconSvg.CircleBgShapeSmall />
        <IconSvg.CircleBgShape />
        <Wrapper>
          <Divider />
          <LeftContent>
            <Head2>
            Our <strong>services</strong>
            </Head2>
            <Paragraph>1. An hour or two for short palliative care by a professional carer</Paragraph>
            <Paragraph>2. Sleepover care where a dedicated carer could assist you several times for
basic to emergency care needs.</Paragraph>
            <CustomerProfileList>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
              <span>
                <img src={Images.image_1} alt="" />
              </span>
            </CustomerProfileList>
          </LeftContent>
          <RightContent>
            <Paragraph className="text-lg">Personal care like bathing, dressing, toileting, etc</Paragraph>
            <Paragraph className="text-lg">Emotional and mental support</Paragraph>
            <Paragraph className="text-lg">Social interaction</Paragraph>
            <Paragraph className="text-lg">
            Assistance with mobility
            </Paragraph>
            <Paragraph className="text-lg">Spiritual empowerment</Paragraph>
            <Paragraph className="text-lg">Pain management care</Paragraph>
            <Paragraph className="text-lg">Hospice care</Paragraph>
          </RightContent>
        </Wrapper>
      </AboutSection>
      <PhotoCollage />

      <CardListing
        heading="Specialised clinical care"
        description="Specialized clinical care is provided by Welcare for the elderly to meet the specific healthcare needs and challenges. Our healthcare staff typically focus on treating particular medical conditions or treatment modalities that call for a deeper level of expertise than a general practitioner may be able to provide.

        "
      >
        <Head4>Our Services</Head4>
        <ServiceCard heading="Geriatric medicine" />
        <ServiceCard heading="Fall prevention programs" />
        <ServiceCard heading="Chronic Disease Management" />
        <ServiceCard heading="Continence Management Programs" />
        <ServiceCard heading="Acute care" />
        
      </CardListing>

    </>
  );
};

export default NursingService;
