import styled from "styled-components";
import { colors } from "../../../common/constants/color";

export const FormAction = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
`

export const RegisterContainer = styled.div`
background-color : ${colors.lightBG};
  display:flex;
  padding: 60px 30px;
  border-radius:6px;
  margin-bottom:60px;
  @media only screen and (max-width: 991px) {
    flex-wrap:wrap;
    } 
` 

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width:50%;
  justify-content:flex-start;
  margin-right:20px;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin-right:0px;
    } 
  img{
    max-height:150px;
    object-fit:contain;
    text-align:left;
    width:150px;
  }
  h4{
    margin-bottom:20px;
  }
  .primary-head{
   color: ${colors.primaryColor};
  }

`;
export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width:50%;
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin-top:30px;
    } 
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
`;
export const AuthBanner = styled.div`

    .heading{
        margin-bottom:24px;
    }
`;
export const QRcontainer = styled.div`
    display:flex;
    background-color:#fff;
    border-radius:6px;
    padding:10px 20px;
    justify-content:space-between;
    margin-bottom:20px;
    align-items:center;
    flex-wrap:wrap;
    @media only screen and (max-width: 767px) {
      justify-content:center;
      text-align:center;
    }
    h4{
      margin-bottom:12px;
    }
`
export const ImgContainer =styled.div`
    border:solid 2px ${colors.primaryColor};
    padding:8px;
    border-radius:6px;

`
export const Contact =styled.div`
    display:flex;
    align-items:center;
    flex-wrap:wrap;
`
export const IconWithLink =styled.div`
    display:flex;
    align-items:center;
    margin-right:15px;
    .phone-icon{
      width:18px;
    }
    a{
      margin-left:6px;
    }
`

export const FormItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 6%;
    }

    > * {
        flex: 1;

        &:first-child:not(:last-child) {
            margin-right: 20px;
        }
    }
`

export const FormItemHalf = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 6%;
    }

    > * {
        flex: 1;  
        width: 100%; 
    }

    > {
        label {
            font-weight: 600;
            font-size: 14px;
            color: #023020;
            margin-bottom: 14px;
        }

        &:not(:last-child) {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      > {
        &:not(:last-child) {
          margin-right: 0px;
        }
      }
    }
`

export const ContactFormCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
`