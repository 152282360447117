import React, { FC } from "react";
import { Head1, Head2, Paragraph } from "../../../../common/constants/core.style";
import { InfoHeader, InfoItem, InfoItemList, LeftContent, MainSection, MainSectionContent, MainSectionWrapper, RightContent } from "./style";
import { IconSvg, Images } from "../../../../common/constants/images";

interface HeroSectionProps {
    heading ?: string;
    description?: string;
}

const HeroSection: FC<HeroSectionProps> = ( props ) => {

    const {
        heading,
        description,
    } = props 
    
    return (
        <MainSection>
            <IconSvg.CircleBgShapeSmall/>
            <IconSvg.CircleBgShapeSmall/>
            <MainSectionWrapper>
                <Head1>{heading}</Head1>
                {description ? (
                    <Paragraph className='text-md'>{description}</Paragraph>
                ): null}
                <MainSectionContent>
                    <LeftContent>
                        <img src={Images.ContactUs} alt="" />
                        <Head2>Get in touch with welcare</Head2>
                    </LeftContent>
                    <RightContent>
                        {/* <InfoItemList>
                            <InfoItem>
                                <InfoHeader>
                                    <IconSvg.EmailIcon/>
                                    <Paragraph>Email</Paragraph>
                                </InfoHeader>
                                <a href="mailto:info@welcare.com">info@welcare.com</a>
                                <a href="mailto:support@welcare.com">support@welcare.com</a>
                            </InfoItem>
                            <InfoItem>
                                <InfoHeader>
                                    <IconSvg.PhoneIcon/>
                                    <Paragraph>Phone</Paragraph>
                                </InfoHeader>
                                <a href="tel:+1 234 234 56 78">+1 234 234 56 78</a>
                                <a href="tel:+1 234 234 90 90">+1 234 234 90 90</a>
                            </InfoItem>
                            <InfoItem>
                                <InfoHeader>
                                    <IconSvg.AddressIcon/>
                                    <Paragraph>Address</Paragraph>
                                </InfoHeader>
                                <Paragraph>2590 N First St 2540, San Jose</Paragraph>
                                <Paragraph>CA 95131, New Zealand</Paragraph>
                            </InfoItem>
                        </InfoItemList> */}
                    </RightContent>
                </MainSectionContent>
            </MainSectionWrapper>
        </MainSection>
    )
}

export default HeroSection;