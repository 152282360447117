import styled from "styled-components";
import { Heading1 } from "../../../../common/constants/core.style";

export const TextContainer = styled.div`
    display : flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    flex-wrap: wrap;

    h2 {
        ${Heading1}
        width: calc(50% - 30px);
        padding-right: 9%;
    }

    p {
        width: calc(50%);
        flex: 1;
    }

    @media only screen and (max-width: 768px) {
        h2 {
            width: 100%;
            margin-bottom: 20px;
        }
    }
`

export const PhotoCollageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        overflow: hidden;
        object-fit: cover;
        &:nth-child(1) {
            grid-column: 1 / span 1;
            grid-row: 1 / span 2;
        }

        &:nth-child(2) {
            grid-column: 2 / span 2;
            grid-row: 1 / span 1;
        }

        &:nth-child(3) {
            grid-column: 2 / span 1;
            grid-row: 2 ;
        }

        &:nth-child(4) {
            grid-column: 3 / span 1;
            grid-row: 2 ;
        }

        &:nth-child(5) {
            grid-column: 4 / span 1;
            grid-row: 1 / span 2 ;
        }
    }

    @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;

        img {
            &:nth-child(1) {
                grid-column: 1 / span 2;
                grid-row: 1 / span 2;
            }
    
            &:nth-child(2) {
                grid-column: 3 / span 2;
                grid-row: 1 / span 1;
            }
    
            &:nth-child(3) {
                grid-column: 1 / span 2;
                grid-row: 3 / span 2 ;
            }
    
            &:nth-child(4) {
                grid-column: 3 / span 2;
                grid-row: 2 ;
            }
    
            &:nth-child(5) {
                grid-column: 3 / span 2;
                grid-row: 3 / span 2 ;
            }
        }
    }
`

export const PhotoCollageWrapper = styled.div`
    width: 100%;
    background-color: #F3F3F8;
    padding: 100px 20px;

    @media only screen and (max-width: 991px) {
        padding: 50px 20px;
    }

`