import React, { FC } from "react";
import { CardWrapper, TextContainer } from "./style";
import { Head2, Paragraph, Section, Wrapper } from "../../constants/core.style";
import Card from "../card";

interface cardListingProp {
  heading: string;
  description: string;
  cardData:  any[];
  cardPerGrid?: number;
}

const CardListing: FC<cardListingProp> = (props) => {
  const {
    heading,
    description,
    cardData,
    cardPerGrid=3,
  } = props

  return (
    <Section>
      <Wrapper>
        <TextContainer>
          <Head2>{heading}</Head2>
          <Paragraph className="text-md">{description}</Paragraph>
        </TextContainer>

        <CardWrapper style={{'--card-per-grid' : `${cardPerGrid}`} as React.CSSProperties}>
          {cardData.map((data, i) => (
            <Card
              heading={data.heading}
              description={data.description}
              url={data.url}
              key={`${data.heading}-${i}`}
            />
          ))}
        </CardWrapper>
      </Wrapper>
    </Section>
  )
}

export default CardListing;