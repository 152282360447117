import React from 'react';

import AppRouter from './common/components/router/appRouter';
import { GlobalStyles } from './common/constants/global.style';
// import { Fonts } from './common/constants/core.style';


function App() {
	return (
		<>
			{/* <Fonts/> */}
			<GlobalStyles/>
			<AppRouter />
    </>
	);
}

export default App;