import React, { FC, useState } from "react";
import { UserDetailContainer, FormItem, FormItemHalf } from "./style";
import { Button, CheckBox, CustomInput, CustomSelect, CustomTextArea } from "../../../../common/components";
import { Head3, Head4, Paragraph } from "../../../../common/constants/core.style";

interface EducationDetailProp {

}

const EducationDetail: FC<EducationDetailProp> = () => {
    const [selectedValue, setSelectedValue] = useState("");
    const options = [
        { label: "Option 1", value: "1" },
        { label: "Option 2", value: "2" },
        { label: "Option 3", value: "3" },
    ];

    return (
        <UserDetailContainer>
            <Head3>Education</Head3>
            <Paragraph>Add education</Paragraph>
            <FormItem>
                <CustomInput
                    label="Level of Education"
                    id="edu_level"
                    type="text"
                    required
                />
            </FormItem>
            <FormItem>
                <CustomInput
                    label="Field of Study"
                    id="edu_field"
                    type="text"
                />
            </FormItem>
            <FormItem>
                <CustomInput
                    label="School / College name"
                    id="institute_name"
                    type="text"
                />
            </FormItem>
            <FormItem>
                <CustomSelect
                    label="Country"
                    options={options}
                    value={selectedValue}
                    onChange={setSelectedValue}
                />
            </FormItem>
            <FormItem>
                <CustomInput
                    label="City, State"
                    id="city"
                    type="text"
                />
            </FormItem>
            <FormItem>
                <label>Time period</label>
                <CheckBox
                    label='Currently Enrolled'
                />
            </FormItem>
            <FormItemHalf>
                <CustomSelect
                    label="From"
                    options={options}
                    value={selectedValue}
                    onChange={setSelectedValue}
                />
                <CustomSelect
                    options={options}
                    value={selectedValue}
                    onChange={setSelectedValue}
                />
            </FormItemHalf>
            <FormItemHalf>
                <CustomSelect
                    label="To"
                    options={options}
                    value={selectedValue}
                    onChange={setSelectedValue}
                />
                <CustomSelect
                    options={options}
                    value={selectedValue}
                    onChange={setSelectedValue}
                />
            </FormItemHalf>
        </UserDetailContainer>
    )
}

export default EducationDetail;