import styled from "styled-components";

export const CheckBoxIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid #454C56;
    margin-right: 8px;
    border-radius: 4px;
    padding: 2px;

    svg {
        width: 0px;
        height: 0px;
        opacity: 0;
        transition: all 0.3s ease-in;
    }
`

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        font-size: 14px;
        color: #454C56;
        display: flex;
        align-items: center;
    }

    input {
        width: 1px;
        height: 1px;
        overflow: hidden;

        &:checked {
            + div {
                svg {
                    width: 18px;
                    height: 18px;
                    opacity: 1;
                }
            }
        }
    }
`