import React, { FC } from "react";
import { ToastContainer } from "./style";

interface ToastProps {
    message?: string;
    show?: boolean;
}

const ToastMessage: FC<ToastProps> = ( props ) => {
    const {
        message,
        show = false,
    }  = props;

    return (
        <ToastContainer  className={show ? 'active' : ''}>
            <span>{message}</span>
        </ToastContainer>
    )
}

export default ToastMessage;