import styled, { css } from 'styled-components';

export const Heading1 = css`
  font-size: 54px;
  font-weight: 500;
`

export const Heading2 = css`
  font-size: 48px;
  font-weight: 500;
`

export const Heading3 = css`
  font-size: 36px;
  font-weight: 600;
`
export const Heading4 = css`
  font-size: 24px;
  font-weight: 600;
`

export const Head1 = styled.h1`
  ${Heading1}
`

export const Head2 = styled.h2`
  ${Heading2}
`

export const Head3 = styled.h3`
  ${Heading3}
`
export const Head4 = styled.h4`
  ${Heading4}
`

export const Paragraph = styled.p`
  font-size: 16px;
  color: #6F6F6F;

  &.text-md{
    font-size: 18px;
  }
  &.text-lg{
    font-size: 20px;
  }
`;

export const Wrapper = styled.div`
  max-width: 1240px;
  margin-inline: auto;

  @media only screen and (max-width: 1240px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const Section = styled.section`
    padding-block: 100px;
    background-color: #F3F3F8;
`; 