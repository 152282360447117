import React, { FC, useState, useEffect } from "react";
import { TextAreacontainer } from "./style";

interface CustomTextAreaProps {
    label?: string;
    required?: boolean;
    id: string;
    orientation?: 'vertical' | 'horizontal';
    size?: 'small' | 'large';
    showValidationMessage?: boolean;
    validationMessage?: string;
    placeholder?: string;
    error?: any;
    inputValue?: string; // Added inputValue prop
    onChange?: (value: string) => void;
}

const CustomTextArea: FC<CustomTextAreaProps> = (props) => {
    const {
        label,
        required = false,
        id,
        orientation = 'vertical',
        size = 'small',
        showValidationMessage = false,
        validationMessage = 'Please Input Comment',
        placeholder,
        error = '',
        inputValue = '', // Added inputValue prop
        onChange
    } = props;

    const [value, setValue] = useState(inputValue);
    const [invalid, setInvalid] = useState(!!validationMessage);

    useEffect(() => {
        setValue(inputValue);
        setInvalid(!!validationMessage);
    }, [inputValue, validationMessage]);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setValue(newValue);

        // Call the provided onChange function with the new value
        onChange && onChange(newValue);
    };

    return (
        <TextAreacontainer className={`wc-input-${orientation} wc-input-${size}`}>
            <label htmlFor={id}>
                {label}
                {required && <span>*</span>}
            </label>
            <textarea
                value={value}
                onChange={handleInputChange}
                placeholder={placeholder}
            />
            {showValidationMessage && invalid && <div id={`${id}-error`} role="alert">{validationMessage}</div>}
            {error && <div id={`${id}-error-message`} style={{ color: 'red' }} role="alert">{error}</div>}
        </TextAreacontainer>
    );
}

export default CustomTextArea;