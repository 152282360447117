import React from 'react';
import { Navigate, Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Home from '../../../module/home/pages/home';
import { Footer, Header } from '../index';
import AboutUs from '../../../module/aboutUs/pages/about';
import ContactUs from '../../../module/contactUs/pages/contact';
import CreateProfile from '../../../module/createProfile/pages/createProfile';
import CareerPage from '../../../module/career/pages/careerPage';
import JobBlog from '../../../module/career/pages/jobBlog';
import RegisterPage from '../../../module/auth/page/register';
import HealthSupport from '../../../module/service/pages/healthSupport';
import HomeService from '../../../module/service/pages/homeService';
import NursingService from '../../../module/service/pages/nursingService';

const AppRouter: React.FC = () => {
    const menuData = [
        { name: 'Home', path: '/' },
        { name: 'About Us', path: '/about-us' },
        { name: 'Contact Us', path: '/contact-us' },
        {
            name: 'Services', subMenu: [
                {name: 'Help at Home Services', path: '/health'},
                {name: 'Nursing Services', path: '/home-service'},
                {name: 'Mental Health and wellbeign services', path: '/nursing'}
            ]
        },
    ]

    return (
        <>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <Header menuList={menuData} buttonLabel='Contact Us' buttonLink='/contact-us' />
                                <Home />
                            </>
                        }
                    />
                    <Route
                        path="/about-us"
                        element={
                            <>
                                <Header menuList={menuData} buttonLabel='Contact Us' buttonLink='/contact-us' />
                                <AboutUs />
                            </>
                        }
                    />
                    <Route
                        path="/contact-us"
                        element={
                            <>
                                <Header menuList={menuData} buttonLabel='Contact Us' buttonLink='/contact-us' />
                                <ContactUs />
                            </>
                        }
                    />
                    <Route
                        path="/create-profile"
                        element={
                            <>
                                <Header buttonLabel='Sign In / Sign Up' buttonLink='/' />
                                <CreateProfile />
                            </>
                        }
                    />
                    <Route
                        path="/career"
                        element={
                            <>
                                <Header menuList={menuData} buttonLabel='Sign In / Sign Up' buttonLink='/find-jobs' />
                                <CareerPage />
                            </>
                        }
                    />
                    <Route
                        path="/find-jobs"
                        element={
                            <>
                                <Header buttonLabel='Contact Us' buttonLink='/contact-us' />
                                <JobBlog />
                            </>
                        }
                    />
                    <Route
                        path="/registrations"
                        element={
                            <>
                                <Header menuList={menuData} buttonLabel='Contact Us' buttonLink='/contact-us' />
                                <RegisterPage />
                            </>
                        }
                    />
                    <Route
                        path="/health"
                        element={
                            <>
                                <Header menuList={menuData} buttonLabel='Contact Us' buttonLink='/contact-us'  />
                                <HealthSupport />
                            </>
                        }
                    />
                    <Route
                        path="/home-service"
                        element={
                            <>
                                <Header menuList={menuData} buttonLabel='Contact Us' buttonLink='/contact-us'  />
                                <HomeService />
                            </>
                        }
                    />
                    <Route
                        path="/nursing"
                        element={
                            <>
                                <Header menuList={menuData} buttonLabel='Contact Us' buttonLink='/contact-us'  />
                                <NursingService />
                            </>
                        }
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer />
            </Router>
        </>
    )
}
export default AppRouter;
