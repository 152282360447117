import { FC } from "react";
import { Head2, Paragraph } from "../../../../common/constants/core.style";
import { IconSvg } from "../../../../common/constants/images";
import { TextContent, ImgContent,AuthBannerContainer } from "./style";

interface CareerBannerProps {}

const Banner: FC<CareerBannerProps> = () => {
  return (
    <AuthBannerContainer>
      <TextContent>
        <Head2 className="heading">We will care for you</Head2>
        <Paragraph className="text-md">
          Providing you with the best health and wellbeing support services,
          Welcare - Kaitiakitanga
        </Paragraph>
      </TextContent>
      <ImgContent>
        <IconSvg.Registerimg className="register-img" />
      </ImgContent>
    </AuthBannerContainer>
  );
};
export default Banner;
