import styled from "styled-components";
import { ButtonContainer } from "../../../../common/components/button/style";
import { Wrapper } from "../../../../common/constants/core.style";

export const ActionContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;

    div {
        &:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
        }
    }
`;

export  const CoustomerCountItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
        &:first-child {
            font-size: 28px;
            color: #111111;
            font-weight: 500;
        }   

        &:last-child {
            font-size: 15px;
            color: #6F6F6F;
            font-weight: 400;
        }
    }
`;

export const CoustomerCount = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 46px;
    margin-top: 80px;
`

export const MainSectionContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > {
        svg {
            position: absolute;
            right: 0px;
            top: 0px;
            height: 100%;
            width: 50%;
            z-index: -1;
        }
    }
`;

export const LeftContent = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: calc(95px + 5%);
    position: relative;
    padding-bottom: 124px;

    > {
        svg {
            position: absolute;
            top: 94px;
            right: 0px;
            width: 100%;
            max-width: 550px;
            height: auto;
        }
    }

    h1 {
        font-weight: 400;
        margin-bottom: 26px;
        padding-right: 1%;

        span {
            color: #EE335E;
            font-weight: 600;
            position: relative;
            display: inline-block;

            svg {
                position: absolute;
                top: 50%;
                left: 45%;
                width: 100%;
                transform: translate(-54%, -58%);
                z-index: -1;
            }
        }
    }
`;

export const RightContent = styled.div`
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    img {
        position: relative;
        bottom: 0px;
        right: 0px;
    }
`;

export const MainSection = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    
    @media only screen and (max-width: 1199px) {

        ${Wrapper} {
            width: 100%;
        }

        ${LeftContent} {
            width: 55%;
        }

        ${RightContent} {
            width: 45%;

            img {
                width: 120%;
            }
        }

        ${MainSectionContent} {
            > {
                svg {
                    width: 40%;
                }
            }
        }
    }

    @media only screen and (max-width: 991px) {
        ${MainSectionContent} {
            flex-direction: column;

            > {
                svg {
                    width: 100%;
                }
            }
        }

        ${LeftContent} {
            width: 100%;
            z-index: 0;

            h1 {
                padding: 0px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: -15px;
                background-color: #fff;
                width: calc(100% + 30px);
                height: 100%;
                z-index: -1;
            }
        }

        ${RightContent} {
            width: 100%;
            padding-top: 20px;

            img {
                width: 100%;
            }
        }
    }
`;
