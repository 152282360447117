import React, { FC } from "react";
import { Head1, Paragraph, Wrapper } from "../../../../common/constants/core.style";
import { LeftContent, MainSection, MainSectionContent, RightContent } from "./style";
import { Images } from "../../../../common/constants/images";

interface HeroSectionProps {
    title?: string;
    description: string;
    titleMain?:string
}

const HeroSection: FC<HeroSectionProps> = ({ title,titleMain, description }) => {
    
    return (
        <MainSection>
            <Wrapper>
                <MainSectionContent>
                    <LeftContent>
                        <Head1>{title} <span>{titleMain}</span></Head1>
                    </LeftContent>
                    <RightContent>
                        <Paragraph className="text-md">{description}</Paragraph>
                    </RightContent>
                </MainSectionContent>
            </Wrapper>
            <img src={Images.AboutUsBanner} alt="" />
        </MainSection>
    )
}

export default HeroSection;