import styled from "styled-components"
import { colors } from "../../../../common/constants/color"

export const FormContent =styled.div`
    display: flex;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
`

export const FormAction = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
`

export const StatusBar = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: var(--progress-status);
    height: 100%;
    background-color: ${colors.primaryColor};
`

export const ProgressBar = styled.div`
    height: 4px;
    width: 100%;
    background-color: #D9D9D9;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
`

export const FormHeader = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: sticky;
    top: 89px;
    background-color: #fff;
    padding: 10px 0px;
    z-index: 89;

    > div {
        &:last-child {
            width: 100%;
            margin-top: 10px;
        }
    }
`

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`