import React, { FC } from "react";
import HeroSection from "../components/mainSection";
import ContactForm from "../components/contactForm";
import { FormSection, FormSectionWrapper, TextContainer } from "./style";
import { Head2, Paragraph } from "../../../common/constants/core.style";
import { IconSvg } from "../../../common/constants/images";
import { MultiText, ToastMessage } from "../../../common/components";

const ContactUs: FC = () => {
    return (
        <>
            <HeroSection
                heading="Contact Us"
            />
            <FormSection>
                <IconSvg.ContactFormBg/>
                <FormSectionWrapper>
                    <TextContainer>
                        <Head2>Contact Us</Head2>
                        <Paragraph className='text-md'>Got a question or suggestion? We would love to hear from you! Our Welcare team is ready to assist you with any queries or concerns you have. Feel free to reach out to us via the contact form given, and we will get back to you soon. Your feedback is valuable to us to provide the best experience possible.</Paragraph>
                    </TextContainer>
                    <ContactForm/>
                </FormSectionWrapper>
            </FormSection>
            {/* <ToastMessage
                message="Toast Message"
            /> */}
            <MultiText/>
        </>
    )
}

export default ContactUs;