import styled from 'styled-components';
import { colors } from '../../../common/constants/color';

export const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;

    span {
        height: 40px;
        aspect-ratio: 1;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        background-color: ${colors.primaryColor};
        color: #fff;
        margin-right: 30px;
    }

    @media only screen and ( max-width: 1024px ) {
        margin: 15px 0px;
    }
`

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0px;
`