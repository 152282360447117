import React, { FC } from "react";
import { Head1, Paragraph, Wrapper } from "../../../../common/constants/core.style";
import { LeftContent, MainSection, MainSectionContent, RightContent } from "./style";
import { Images } from "../../../../common/constants/images";

interface HeroSectionProps {

}

const HeroSection: FC<HeroSectionProps> = () => {

    return (
        <MainSection>
            <Wrapper>
                <MainSectionContent>
                    <LeftContent>
                        <Head1>Providing you with the best health and wellbeing support services, Welcare- Kaitiakitanga</Head1>
                    </LeftContent>
                    <RightContent>
                        <Paragraph className="text-md">Welcare- Your partner in elderly care Every wrinkle tells a story, and we're here to listen Brightening the twilight years with compassion and companionship</Paragraph>
                    </RightContent>
                </MainSectionContent>
            </Wrapper>
            <img src={Images.AboutUsBanner} alt="" />
        </MainSection>
    )
}

export default HeroSection;